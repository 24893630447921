import moment from 'moment';

export const getName = (name, date) => `${name} - ${moment(date).format('DD/MM/YYYY hh:mm:ss')}`;

export const distance = (x1, y1, x2, y2) => {
  const a = x1 - x2;
  const b = y1 - y2;

  return Math.sqrt(a * a + b * b);
};
