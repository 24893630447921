import { createTypes } from 'store/utils';

export const KEY = 'socket';

export const CONNECTED = 'connected';
export const SET_CONNECTED = 'SET_CONNECTED';
export const QUEUE = 'queue';
export const SET_QUEUE = 'SET_QUEUE';

export const MyTypesArray = [SET_CONNECTED, SET_QUEUE];
export const TYPES = createTypes(KEY, MyTypesArray);
