import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { FormGroup } from 'components/Menu';

import { useTranslation } from 'react-i18next';
import { fetchSurveyOptions, setSurveyFilter } from './redux/actions';
import { getSurveyOptions, getSurveyFilter } from './redux/selectors';

import { getLang } from '../../App/redux/selectors';
import useActions from '../../hooks/useActions';

import './restitution.scss';

const SurveySettings = () => {
  const { t } = useTranslation();

  const lang = useSelector(getLang);
  const surveyOptions = useSelector(getSurveyOptions);
  const surveyFilter = useSelector(getSurveyFilter);

  const onFetchSurveyOptions = useActions(fetchSurveyOptions);
  const onSetSurveyFilter = useActions(setSurveyFilter);

  useEffect(
    () => {
      if (lang) {
        onFetchSurveyOptions(lang);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lang],
  );

  const onSetValue = (questionId, value) => {
    onSetSurveyFilter({ ...surveyFilter, [questionId]: value });
  };

  return (
    <FormGroup label={t('restitution.survey')}>
      <div className="survey-settings">
        {surveyOptions.map((question) => (
          <FormGroup key={question.id} label={question.text}>
            <Select
              options={question.options}
              value={surveyFilter[question.id]}
              onChange={(value) => {
                onSetValue(question.id, value);
              }}
              isMulti
            />
          </FormGroup>
        ))}
      </div>
    </FormGroup>
  );
};

export default SurveySettings;
