import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import Menu, { Button } from 'components/Menu';
import Ratio from 'components/Ratio';
import Overlay from 'components/Overlay';
import { useTranslation } from 'react-i18next';
import {
  getCorpus,
  getResult,
  getSelectedSessions,
  getSettings,
  getSettingsOpen,
  getSlidersOpen,
} from './redux/selectors';
import Settings from './Settings';
import Sliders from './Sliders';
import {
  setSettingsOpen, setSlidersOpen, setSelectedSessions, share,
  showExplanation, hideExplanation, selectExplanationDrawing, selectExplanationSection, shareDrawing,
} from './redux/actions';
import { getRestitutionOpen } from '../../App/redux/selectors';
import roles from '../../App/roles';
import Session from './Session';

import { Help } from '../../components/Overlay';
import NextBackground from '../../components/NextBackground';
import Explanation from '../../components/Explanation';
import useActions from '../../hooks/useActions';
import { setExplanationOpen } from '../../components/Explanation/redux/actions';
import { getExplanationOpen } from '../../components/Explanation/redux/selectors';

import './restitution.scss';

const RestitutionProf = ({
  role,
  onBackground,
}) => {
  const sessions = useRef();
  const [helpOpen, setHelpOpen] = useState(false);
  const [selectedDrawing, setSelectedDrawing] = useState();
  const { t } = useTranslation();

  const corpus = useSelector(getCorpus);
  const result = useSelector(getResult);
  const selectedSessions = useSelector(getSelectedSessions);
  const settings = useSelector(getSettings);
  const settingsOpen = useSelector(getSettingsOpen);
  const slidersOpen = useSelector(getSlidersOpen);
  const restitutionOpen = useSelector(getRestitutionOpen);

  const explanationOpen = useSelector(getExplanationOpen);
  const onSetExplanationOpen = useActions(setExplanationOpen);
  const onShareExplanation = useActions(showExplanation);
  const onUnshareExplanation = useActions(hideExplanation);
  const onSelectExplanationDrawing = useActions(selectExplanationDrawing);
  const onSelectExplanationSection = useActions(selectExplanationSection);
  const onSetSettingsOpen = useActions(setSettingsOpen);
  const onSetSlidersOpen = useActions(setSlidersOpen);
  const onSetSelectedSessions = useActions(setSelectedSessions);
  const onShare = useActions(share);
  const onShareDrawing = useActions(shareDrawing);

  const helpItems = [
    {
      id: 'help1',
      icon: 'minus',
      desc: t('restitution.help.unselect'),
    },
    {
      id: 'help2',
      icon: 'wrench',
      desc: t('restitution.help.mark'),
    },
    {
      id: 'help3',
      icon: 'sliders',
      desc: t('restitution.help.settings'),
      className: 'slides',
    },
    {
      id: 'help4',
      icon: 'arrow-right',
      desc: t('restitution.help.close'),
    },
  ];

  useEffect(() => {
    setSelectedDrawing();
  }, [result]);

  const onSettingsOpen = () => {
    onSetSettingsOpen(!settingsOpen);
    onSetSlidersOpen(false);
  };

  const onSlidersOpen = () => {
    onSetSlidersOpen(!slidersOpen);
    onSetSettingsOpen(false);
  };

  const handleShare = () => {
    const sharedSessions = Object.keys(selectedSessions).map((id) => {
      const item = result.find((i) => i.id === id);

      return {
        id,
        src: corpus.url,
        name: item.name,
        drawings: item.drawings,
        settings: settings[id],
      };
    });

    onShare(sharedSessions);
    setSelectedDrawing();
    onShareDrawing();
  };

  const onDrawingSelect = (drawing) => {
    if (drawing) {
      const { user: a, session: b, ...other } = drawing;
      onShareDrawing(other);
    } else {
      onShareDrawing();
    }
    setSelectedDrawing(drawing);
  };

  const onSelect = (id) => {
    if (result.length !== 1) {
      if (selectedSessions[id]) {
        onSetSelectedSessions(
          Object.keys(selectedSessions).reduce((object, key) => {
            if (key !== id) {
              object[key] = selectedSessions[key];
            }
            return object;
          }, {}),
        );
      } else {
        onSetSelectedSessions({ ...selectedSessions, [id]: true });
      }
    }
  };

  const onHelpToggle = () => {
    setHelpOpen(!helpOpen);
  };

  const noResult = !(result && result.length);
  const noSelection = !Object.keys(selectedSessions).length;

  const onToggleSelection = () => {
    if (noSelection) {
      onSetSelectedSessions(
        result.reduce((object, item) => {
          object[item.id] = true;
          return object;
        }, {}),
      );
    } else {
      onSetSelectedSessions({});
    }
  };

  let content = null;
  if (!noResult) {
    if (result.length === 1) {
      const [{ id, drawings, name }] = result;
      content = (
        <Session
          {...{
            id,
            onSelect,
            name,
            src: corpus && corpus.url,
            drawings,
            settings: settings[id],
            clickable: true,
            onDrawingSelect,
            selectedDrawing: selectedDrawing && selectedDrawing.sessionId === id && selectedDrawing,
          }}
        />
      );
    } else {
      content = (
        <>
          {result.map(({ id, drawings, name }) => (
            <Ratio key={id} className={classNames({ selected: selectedSessions[id] })}>
              <Session
                {...{
                  id,
                  onSelect,
                  name,
                  src: corpus && corpus.url,
                  drawings,
                  settings: settings[id],
                  clickable: true,
                  onDrawingSelect,
                  selectedDrawing: selectedDrawing && selectedDrawing.sessionId === id && selectedDrawing,
                }}
              />
            </Ratio>
          ))}
        </>
      );
    }
  }

  const onForceExplain = () => {
    onSetExplanationOpen(false);
    onUnshareExplanation();
  };

  const onExplain = () => {
    onShareExplanation();
  };

  const [choicesOpen, setChoicesOpen] = useState(false);

  const onChoicesOpen = () => {
    setChoicesOpen(true);
    onSetSlidersOpen(false);
    onSetSettingsOpen(false);
  };

  const onChoicesClose = () => {
    setChoicesOpen(false);
  };

  return (
    <>
      <Overlay open={restitutionOpen} className="restitution">
        <Help items={helpItems} open={helpOpen} onClose={onHelpToggle} />
        <div className="menu-container restitution">
          <Menu>

            {explanationOpen ? (
              <Button icon="close" onClick={onForceExplain} />
            ) : (
              <>
                <Button icon="question" onClick={onHelpToggle} />
                <Button
                  icon={noSelection ? 'plus' : 'minus'}
                  onClick={onToggleSelection}
                  disabled={(result || []).length < 2}
                />
                <Button
                  icon="share"
                  onClick={handleShare}
                  disabled={noResult || noSelection}
                  test={(r) => r === roles.PROF}
                />
                <Button icon="wrench" onClick={onSettingsOpen} active={settingsOpen} />

                <Button
                  className="slides"
                  icon="sliders"
                  onClick={onSlidersOpen}
                  active={slidersOpen}
                  disabled={noResult || noSelection}
                />
                <Button icon="arrow-right" onClick={onChoicesOpen} />
              </>
            )}
          </Menu>
          {!explanationOpen && <Settings role={role} />}
          <Sliders />
          {explanationOpen ? (
            <Explanation
              onSelect={onSelectExplanationDrawing}
              onSection={onSelectExplanationSection}
              onExplain={onExplain}
            />
          )
            : (
              <div className="sessions" ref={sessions}>
                {content}
              </div>
            )}
        </div>
      </Overlay>
      <NextBackground
        onBackground={onBackground}
        onExplain={onExplain}
        overlayOpen={choicesOpen}
        onClose={onChoicesClose}
      />
    </>
  );
};

RestitutionProf.propTypes = {

};

RestitutionProf.defaultProps = {

};

export default RestitutionProf;
