import {
  KEY,
  STUDENTS,
  SESSIONS_LIST,
  SESSIONS,
  CORPUS,
  NUMBER,
  SUPERPOSE,
  MODE,
  CORPUS_LIST,
  STUDENTS_LIST,
  DATA,
  RESULT,
  SETTINGS_OPEN,
  SLIDERS_OPEN,
  LINE_WIDTH,
  BLUR,
  VALUE,
  SELECTED_SESSIONS,
  SETTINGS,
  SURVEY_OPTIONS,
  SURVEY_FILTER, FILE, SESSIONS_DRAWING,
} from './constants';

export const getSessionsList = (state) => state[KEY][SESSIONS_LIST];
export const getSessions = (state) => state[KEY][SESSIONS];
export const getSessionsDrawing = (state) => state[KEY][SESSIONS_DRAWING];
export const getCorpusList = (state) => state[KEY][CORPUS_LIST];
export const getCorpus = (state) => state[KEY][CORPUS];
export const getStudentsList = (state) => state[KEY][STUDENTS_LIST];
export const getStudents = (state) => state[KEY][STUDENTS];
export const getNumber = (state) => state[KEY][NUMBER];
export const getSuperpose = (state) => state[KEY][SUPERPOSE];
export const getMode = (state) => state[KEY][MODE];
export const getData = (state) => state[KEY][DATA];
export const getResult = (state) => state[KEY][RESULT];
export const getFile = (state) => state[KEY][FILE];
export const getSettingsOpen = (state) => state[KEY][SETTINGS_OPEN];
export const getSlidersOpen = (state) => state[KEY][SLIDERS_OPEN];

export const getLineWidth = (state) => state[KEY][LINE_WIDTH];
export const getBlur = (state) => state[KEY][BLUR];
export const getValue = (state) => state[KEY][VALUE];
export const getSelectedSessions = (state) => state[KEY][SELECTED_SESSIONS];
export const getSettings = (state) => state[KEY][SETTINGS];
export const getSurveyOptions = (state) => state[KEY][SURVEY_OPTIONS];
export const getSurveyFilter = (state) => state[KEY][SURVEY_FILTER];
