import React, { Suspense, useEffect } from 'react';
import Loadable from 'react-loadable';
import LoadingComponent from 'components/Loading';

import store, { persistor } from 'store';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import A2HSProvider from 'a2hs';
import { PersistGate } from 'redux-persist/integration/react';
// import FullScreen from 'components/FullScreen';
import { history } from 'routes';
// import AppleDebug from 'components/AppleDebug';

import 'i18n';
import LocaleWatch from 'i18n/LocaleWatch';

import 'assets/fonts/ikonikat.css';
import './theme.scss';
import './app.scss';
import logger from 'js-logger';
import 'prevent-pull-refresh';

logger.useDefaults({ defaultLevel: logger.INFO });

const Loading = () => <LoadingComponent />;
export const RootAsync = Loadable({
  loader: () => import('components/Layout'),
  loading: () => <Loading open />,
});

const App = () => {
  useEffect(() => {
    // Disable context menu as in interferes with user drawing
    // document.addEventListener('contextmenu', (event) => event.preventDefault());
  }, []);

  return (
    <Suspense fallback={<Loading open />}>
      <A2HSProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router history={history}>
              <Switch>
                <Route>
                  <LocaleWatch />
                  {/* <FullScreen /> */}
                  {/* <AppleDebug /> */}
                  <RootAsync />
                </Route>
              </Switch>
            </Router>
          </PersistGate>
        </Provider>
      </A2HSProvider>
    </Suspense>
  );
};

export default App;
