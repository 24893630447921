import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import OriginalDrawing from 'components/Drawing';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/Menu';
import { Help } from 'components/Overlay';
import useActions from '../../hooks/useActions';
import { getBackground } from '../Drawer/redux/selectors';
import Slate from '../Slate';

import './explanation.scss';
import {
  fetchExplanation, setExplanation, setExplanationOpen,
  setSelectedDrawing, fetchExplanationBackground, nextExplanation, prevExplanation,
} from './redux/actions';
import {
  getCurrentIndex, getExplanation, getSection, getSelectedDrawing,
} from './redux/selectors';
import { distance } from '../../utils';

const selectedColor = {
  c1: 'ff0000',
  c2: 'ff0000',
  id: 'carre_violet',
  n: 'rose',
};

export const useExplanationBackground = () => {
  const currentIndex = useSelector(getCurrentIndex);
  const background = useSelector(getBackground);
  const onFetchExplanationBackground = useActions(fetchExplanationBackground);

  useEffect(() => {
    onFetchExplanationBackground(currentIndex);
  }, [currentIndex, onFetchExplanationBackground]);

  return background;
};

export const useExplanation = () => {
  const explanation = useSelector(getExplanation);
  const background = useSelector(getBackground);
  const onFetchExplanation = useActions(fetchExplanation);
  const onSetExplanation = useActions(setExplanation);

  useEffect(() => {
    if (background) {
      onSetExplanation();
      onFetchExplanation(background.id);
    }
  }, [background, onFetchExplanation, onSetExplanation]);

  return explanation;
};

export const ExplanationButton = ({ onClick }) => {
  const explanation = useExplanation();

  return explanation ? <Button icon="info" onClick={onClick} /> : null;
};

export const Drawing = ({
  drawing, size, selectedDrawing, explanation, onClick,
}) => {
  const color = useMemo(() => (selectedDrawing && (selectedDrawing.id === drawing.id || selectedDrawing.id === drawing.associatedDrawing)
    ? (drawing.selectedColor || explanation.selectedColor || selectedColor)
    : drawing.color || explanation.color), [drawing, explanation.color, explanation.selectedColor, selectedDrawing]);

  return (
    <OriginalDrawing
      drawing={drawing}
      width={size.width}
      height={size.height}
      color={color}
      onClick={onClick}
    />
  );
};

const Explanation = ({
  onSelect, disabled, defaultText,
}) => {
  const [size, setSize] = useState({});
  const selectedDrawing = useSelector(getSelectedDrawing);
  const onSetSelectedDrawing = useActions(setSelectedDrawing);
  const onSetExplanationOpen = useActions(setExplanationOpen);
  const onNextExplanation = useActions(nextExplanation);
  const onPrevExplanation = useActions(prevExplanation);
  const section = useSelector(getSection);

  const { t } = useTranslation();

  const background = useExplanationBackground();
  const explanation = useExplanation();

  const setDrawing = useCallback((drawing) => {
    onSetSelectedDrawing(drawing);
    if (onSelect) {
      onSelect(drawing);
    }
  }, [onSelect, onSetSelectedDrawing]);

  useEffect(() => {
    if (explanation) {
      setDrawing(explanation.drawings[0]);
    }
  }, [explanation, setDrawing]);

  const onDrawingClick = (e) => {
    if (!disabled) {
      const rect = e.target.getBoundingClientRect();
      const posX = e.clientX - rect.left;
      const posY = e.clientY - rect.top;

      let minDistance = 100000000;
      let drawing;

      explanation.drawings.forEach((item) => {
        const xFactor = size.width / item.width;
        const yFactor = size.height / item.height;

        const check = ({ x, y }) => {
          const dist = distance(x * xFactor, y * yFactor, posX, posY);
          if (dist < minDistance) {
            minDistance = dist;
            drawing = item;
          }
        };

        if (item.subDrawings) {
          [].concat(...item.subDrawings).forEach(check);
        } else {
          item.dots.forEach(check);
        }
      });

      setDrawing(drawing);
    }
  };

  const index = useMemo(() => explanation && selectedDrawing && explanation.drawings.findIndex(
    ({ id }) => id === selectedDrawing.id,
  ), [explanation, selectedDrawing]);

  const onPrev = useCallback(() => {
    if (explanation) {
      if (index > 0) {
        const drawing = explanation.drawings[index - 1];
        setDrawing(drawing);
      }
    }
  }, [explanation, index, setDrawing]);

  const onNext = useCallback(() => {
    if (explanation) {
      if (index < explanation.drawings.length - 1) {
        const drawing = explanation.drawings[index + 1];
        setDrawing(drawing);
      }
    }
  }, [explanation, index, setDrawing]);

  const prevDisabled = useMemo(() => index === 0, [index]);
  const nextDisabled = useMemo(() => index === (
    explanation && explanation.drawings.length - 1), [explanation, index]);

  const onClose = () => {
    onSetExplanationOpen(false);
  };

  const text = useMemo(() => {
    if (explanation) {
      switch (section) {
        case 'title':
        case 'subject':
        case 'author':
          return <div className="explanation-info" dangerouslySetInnerHTML={{ __html: explanation[section] }} />;
        case 'explanation': {
          const defaultTextValue = defaultText || (
          <>
            <button
              type="button"
              className="button explanation-action"
              onClick={() => { setDrawing(explanation.drawings[0]); }}
            >
              {t('explanation.start')}
            </button>
            <br />
            {t('explanation.or')}
            <br />
            <br />
            {t('explanation.selectDrawing')}
          </>
          );
          return selectedDrawing
            ? (
              <div className="explanation-action-navigate">
                {!disabled && (
                <div className="arrow-left">
                  <Button icon="angle-double-left" onClick={onPrev} disabled={prevDisabled} />
                </div>
                )}
                <div className="explanation-text" dangerouslySetInnerHTML={{ __html: selectedDrawing.text }} />
                {!disabled && (
                <div className="arrow-right">
                  <Button icon="angle-double-right" onClick={onNext} disabled={nextDisabled} />
                </div>
                )}
              </div>
            )
            : (
              <div className="explanation-default">
                {defaultTextValue}
              </div>
            );
        }
        default:
          break;
      }
    }
    return '';
  }, [defaultText, disabled, explanation, nextDisabled, onNext, onPrev, prevDisabled, section, selectedDrawing, setDrawing, t]);

  const displayDrawings = useMemo(() => explanation && explanation.drawings && section === 'explanation', [explanation, section]);

  const infoItems = useMemo(
    () => {
      if (explanation) {
        return [
          {
            id: 'help1',
            desc: (
              <span dangerouslySetInnerHTML={{ __html: explanation.title }} />
            ),
          },
        ];
      }
      return [];
    },
    [explanation],
  );

  const helpItems = useMemo(
    () => [
      {
        id: 'help1',
        desc: <strong className="single-help-title">{t('single.help.title2')}</strong>,
      },
      {
        id: 'help2',
        desc: (
          <span>
            {t('explanation.help.info')}
            {' '}
            <Button icon="info" className="help-text-button" />
          </span>
        ),
      },
      {
        id: 'help3',
        desc: (
          <span>
            {t('explanation.help.next')}
            {' '}
            <Button icon="arrow-right" className="help-text-button" />
          </span>
        ),
      },
      {
        id: 'help4',
        desc: (
          <span>
            {t('explanation.help.prev')}
            {' '}
            <Button icon="arrow-left" className="help-text-button" />
          </span>
        ),
      },
      {
        id: 'help5',
        desc: (
          <span>
            {t('explanation.help.navigate')}
            {' '}
            <Button icon="angle-double-left" className="help-text-button" />
            <Button icon="angle-double-right" className="help-text-button" />
          </span>
        ),
      },
    ],
    [t],
  );

  const [infoOpen, setInfoOpen] = useState(false);

  const onInfo = () => {
    setInfoOpen(true);
  };

  const onInfoClose = () => {
    setInfoOpen(false);
  };

  const [helpOpen, setHelpOpen] = useState(false);

  const onHelpClose = () => {
    setHelpOpen(false);
  };

  const currentIndex = useSelector(getCurrentIndex);

  const handleNext = () => {
    if (currentIndex + 1 > 4) {
      onNextExplanation('/moodle/complete');
    } else {
      onNextExplanation('/moodle/complete');
    }
  };

  return background && explanation ? (
    <div className="explanation _dummy">
      <div className="close" onClick={onClose}>
        <i className="la la-times" />
      </div>
      <div className="explanation-drawings">
        <Slate src={background.url} onSizeChange={setSize}>
          {displayDrawings
            && explanation.drawings.map((drawing) => (
              <Drawing
                key={drawing.id}
                {...{
                  drawing, size, explanation, selectedDrawing,
                }}
                onClick={onDrawingClick}
              />
            ))}
        </Slate>
      </div>
      <div className="explanation-container">
        <div className="explanation-commands">
          {text}
        </div>
      </div>
      <div className="explanation__actions">
        <Button className="" icon="info" onClick={onInfo} />
        <Button className="" icon="arrow-right" onClick={handleNext} />
        {currentIndex > 0 && <Button className="" icon="arrow-left" onClick={() => { onPrevExplanation(); }} />}
      </div>

      <Help items={infoItems} open={infoOpen} onClose={onInfoClose} />
      <Help items={helpItems} open={helpOpen} onClose={onHelpClose} />
    </div>
  ) : null;
};

Explanation.propTypes = {
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  defaultText: PropTypes.string,
};

Explanation.defaultProps = {
  onSelect: undefined,
  disabled: false,
  defaultText: undefined,
};

export default Explanation;
