import { history } from 'routes';
import store from 'store';
import {
  getUser,
} from '../../App/redux/selectors';

const singleRoute = '/single';

export const getNextRoute = (route) => {
  const user = getUser(store.getState());
  switch (route) {
    case '/':
      if (user.survey_complete) {
        return '/end';
      }
      if (user.activity_complete) {
        return '/survey';
      }
      if (!user.tutorial_complete) {
        return '/tutorial';
      }
      return singleRoute;
    case '/tutorial':
      return singleRoute;
    case '/survey':
      return '/end';
    case '/end':
      return '/single';
    default:
  }
};

export const nextRoute = (route, params = {}) => {
  history.push(getNextRoute(route, params));
};
