import { createAction } from 'store/utils';

import logger from 'js-logger';
import ajaxHandler from 'network/ajaxHandler';
import store from 'store';

import {
  CHANGE_VISIBILITY,
  CAN_REMOVE,
  CHANGE_MODE,
  MAX_DRAWINGS,
  LINE_DETECTION,
  UI_DISABLED,
} from 'components/Drawer/redux/constants';
import {
  setChangeVisibility,
  setCanRemove,
  setChangeMode,
  setMaxDrawings,
  setLineDetection,
  setUIDisabled,
} from 'components/Drawer/redux/actions';
import roles from 'App/roles';
import { history } from 'routes';
import {
  TYPES,
  SET_GROUP_ID,
  SET_ROLE,
  SET_USER,
  SET_LOADING,
  SET_LANG,
  SET_LANGS_OPEN,
  LANG,
  SET_GROUP_CLOSED,
  SET_SHARED_DRAWINGS,
  SET_RESTITUTION_OPEN,
  ENDING_SURVEY,
  SET_DISPLAY_SURVEY,
  DISPLAY_SURVEY,
  SET_ENDING_SURVEY,
  DISPLAY_TUTORIAL,
  SET_DISPLAY_TUTORIAL,
  SET_TUTORIAL_BACKGROUND,
  TUTORIAL_BACKGROUND, DISPLAY_DRAWING_COMMENT, SET_DISPLAY_DRAWING_COMMENT,
} from './constants';
import { getUser } from './selectors';
import { nextRoute } from '../../modules/Next';
import { reset, setBackground, setColor } from '../../components/Drawer/redux/actions';
import { getBackground } from '../../components/Drawer/redux/selectors';
import tokenHandler from '../../utils/tokenHandler';

export const setUser = createAction(TYPES[SET_USER]);
export const setLoading = createAction(TYPES[SET_LOADING]);
export const setGroupId = createAction(TYPES[SET_GROUP_ID]);
export const setRole = createAction(TYPES[SET_ROLE]);
export const setLang = createAction(TYPES[SET_LANG]);
export const setLangsOpen = createAction(TYPES[SET_LANGS_OPEN]);
export const setGroupClosed = createAction(TYPES[SET_GROUP_CLOSED]);
export const doSetSharedDrawings = createAction(TYPES[SET_SHARED_DRAWINGS]);
export const setRestitutionOpen = createAction(TYPES[SET_RESTITUTION_OPEN]);

export const setDisplaySurvey = createAction(TYPES[SET_DISPLAY_SURVEY]);
export const setEndingSurvey = createAction(TYPES[SET_ENDING_SURVEY]);
export const setDisplayTutorial = createAction(TYPES[SET_DISPLAY_TUTORIAL]);
export const setDisplayDrawingComment = createAction(TYPES[SET_DISPLAY_DRAWING_COMMENT]);
export const setTutorialBackground = createAction(TYPES[SET_TUTORIAL_BACKGROUND]);

const actions = {
  [CHANGE_VISIBILITY]: setChangeVisibility,
  [CAN_REMOVE]: setCanRemove,
  [CHANGE_MODE]: setChangeMode,
  [MAX_DRAWINGS]: setMaxDrawings,
  [LINE_DETECTION]: setLineDetection,
  [UI_DISABLED]: setUIDisabled,
  [LANG]: setLang,
  [DISPLAY_SURVEY]: setDisplaySurvey,
  [ENDING_SURVEY]: setEndingSurvey,
  [DISPLAY_TUTORIAL]: setDisplayTutorial,
  [DISPLAY_DRAWING_COMMENT]: setDisplayDrawingComment,
  [TUTORIAL_BACKGROUND]: setTutorialBackground,
};

// Trigger setting change (prof only)
export const setSharedDrawings = (drawings) => async (dispatch) => {
  const userId = getUser(store.getState()).id;
  dispatch(doSetSharedDrawings(drawings.filter(({ studentId }) => studentId !== userId)));
};

export const changeSetting = (groupId, name, value, otherAction) => async (dispatch) => {
  try {
    ajaxHandler.send('group/setting', { groupId, name, value });
    dispatch((otherAction || actions[name])(value));
  } catch (e) {
    logger.warn('group setting change error ', e.status, e.message);
  }
};

// Init settings (students only)
export const setSettings = (settings) => async (dispatch) => {
  Object.keys(settings).forEach((key) => {
    if (actions[key] && settings[key]) {
      dispatch(actions[key](settings[key]));
    }
  });
};

// Update setting (students only)
export const updateSetting = (name, value) => {
  store.dispatch(actions[name](value));
};

export const resetUser = () => async (dispatch) => {
  const user = await ajaxHandler.fetch('user', {});
  dispatch(setUser(user));
};

export const getRedirectLink = () => async () => {
  const link = await ajaxHandler.fetch('user/redirect', {});
  return link;
};

export const fetchUser = () => async (dispatch) => {
  dispatch(setLoading(true));
  const { student: userInfo, token } = await ajaxHandler.fetch('moodle/student', undefined, true);

  tokenHandler.setToken(token);

  if (userInfo) {
    dispatch(setRole(roles.SINGLE));
    dispatch(setColor(userInfo.color));

    dispatch(setSettings(userInfo.settings));

    dispatch(setUser(userInfo));
    dispatch(setGroupId(userInfo.session_fk));

    const background = getBackground(store.getState());
    if (!background || userInfo.background.id !== background.id) {
      const drawingsId = `${userInfo.background.id}_${new Date().getTime()}`;
      dispatch(setBackground({ ...userInfo.background, drawingsId }));
    }

    dispatch(setLoading(false));
  } else {
    dispatch(setLoading(false));
    history.push('/moodle/user-error');
  }
};

export const endTutorial = () => async (dispatch) => {
  dispatch(setLoading(true));

  const user = getUser(store.getState());

  if (user.background) {
    dispatch(setBackground(user.background));
  }

  dispatch(setUser({ ...user, tutorial_complete: true }));

  await ajaxHandler.send('moodle/tutorial', { });

  nextRoute('/tutorial');

  dispatch(setLoading(false));
};

export const nextBackground = () => async (dispatch) => {
  dispatch(setLoading(true));

  dispatch(reset());

  const token = tokenHandler.getToken();

  document.location.href = `/api/moodle/next?token=${token}`;

  dispatch(setLoading(false));
};

export const checkActivity = () => async (dispatch) => {
  dispatch(setLoading(true));
  const isComplete = await ajaxHandler.fetch('moodle/activity/check');
  dispatch(setUser({
    ...getUser(store.getState()),
    activity_complete: isComplete,
  }));
  dispatch(setLoading(false));
};
