import { createTypes } from 'store/utils';

export const KEY = 'restitution';

export const DATA = 'data';
export const SET_DATA = 'SET_DATA';

export const SESSIONS_LIST = 'sessionsList';
export const SET_SESSIONS_LIST = 'SET_SESSIONS_LIST';

export const SESSIONS = 'sessions';
export const SET_SESSIONS = 'SET_SESSIONS';

export const SESSIONS_DRAWING = 'sessionsDrawing';
export const SET_SESSIONS_DRAWING = 'SET_SESSIONS_DRAWING';

export const CORPUS_LIST = 'corpusList';
export const SET_CORPUS_LIST = 'SET_CORPUS_LIST';

export const CORPUS = 'corpus';
export const SET_CORPUS = 'SET_CORPUS';

export const STUDENTS_LIST = 'studentsList';
export const SET_STUDENTS_LIST = 'SET_STUDENTS_LIST';

export const STUDENTS = 'students';
export const SET_STUDENTS = 'SET_STUDENTS';

export const NUMBER = 'number';
export const SET_NUMBER = 'SET_NUMBER';

export const SUPERPOSE = 'superpose';
export const SET_SUPERPOSE = 'SET_SUPERPOSE';

export const MODE = 'mode';
export const SET_MODE = 'SET_MODE';

export const RESULT = 'result';
export const SET_RESULT = 'SET_RESULT';

export const FILE = 'file';
export const SET_FILE = 'SET_FILE';

export const SETTINGS_OPEN = 'settingsOpen';
export const SET_SETTINGS_OPEN = 'SET_SETTINGS_OPEN';

export const SLIDERS_OPEN = 'slidersOpen';
export const SET_SLIDERS_OPEN = 'SET_SLIDERS_OPEN';

export const LINE_WIDTH = 'lineWidth';
export const SET_LINE_WIDTH = 'SET_LINE_WIDTH';

export const BLUR = 'blur';
export const SET_BLUR = 'SET_BLUR';

export const VALUE = 'value';
export const SET_VALUE = 'SET_VALUE';

export const SELECTED_SESSIONS = 'selectedSessions';
export const SET_SELECTED_SESSIONS = 'SET_SELECTED_SESSIONS';

export const SETTINGS = 'settings';
export const SET_SETTINGS = 'SET_SETTINGS';

export const SURVEY_OPTIONS = 'surveyOptions';
export const SET_SURVEY_OPTIONS = 'SET_SURVEY_OPTIONS';

export const SURVEY_FILTER = 'surveyFilter';
export const SET_SURVEY_FILTER = 'SET_SURVEY_FILTER';

export const MyTypesArray = [
  SET_SESSIONS_LIST,
  SET_SESSIONS,
  SET_CORPUS,
  SET_STUDENTS,
  SET_NUMBER,
  SET_SUPERPOSE,
  SET_MODE,
  SET_CORPUS_LIST,
  SET_STUDENTS_LIST,
  SET_DATA,
  SET_RESULT,
  SET_SETTINGS_OPEN,
  SET_SLIDERS_OPEN,
  SET_LINE_WIDTH,
  SET_BLUR,
  SET_VALUE,
  SET_SELECTED_SESSIONS,
  SET_SETTINGS,
  SET_SURVEY_OPTIONS,
  SET_SURVEY_FILTER,
  SET_FILE,
  SET_SESSIONS_DRAWING,
];
export const TYPES = createTypes(KEY, MyTypesArray);
