import socketHandler from 'network/socketHandler';
import store from 'store';
import { setExplanationOpen, setSection, setSelectedDrawing } from '../../../components/Explanation/redux/actions';
import { setSessions, setSessionsDrawing } from '../redux/actions';

const sessionsShareListener = (sessions) => {
  store.dispatch(setSessions(sessions));
};

const sessionsDrawingShareListener = (drawing) => {
  store.dispatch(setSessionsDrawing(drawing));
};

const explanationShowListener = () => {
  store.dispatch(setExplanationOpen(true));
};

const explanationHideListener = () => {
  store.dispatch(setExplanationOpen(false));
};

const explanationSelectListener = (drawing) => {
  store.dispatch(setSelectedDrawing(drawing));
};

const explanationSectionListener = (section) => {
  store.dispatch(setSection(section));
};

export const initHandlers = () => {
  socketHandler.listen('sessions/share', sessionsShareListener);
  socketHandler.listen('sessions/drawing/share', sessionsDrawingShareListener);
  socketHandler.listen('sessions/explanation/show', explanationShowListener);
  socketHandler.listen('sessions/explanation/hide', explanationHideListener);
  socketHandler.listen('sessions/explanation/select', explanationSelectListener);
  socketHandler.listen('sessions/explanation/section', explanationSectionListener);
};

export const stopHandlers = () => {
  socketHandler.stop('sessions/share', sessionsShareListener);
  socketHandler.stop('sessions/explanation/show', explanationShowListener);
  socketHandler.stop('sessions/explanation/hide', explanationHideListener);
  socketHandler.stop('sessions/explanation/select', explanationSelectListener);
  socketHandler.stop('sessions/explanation/section', explanationSectionListener);
};
