import { createReducer, assignPayloadToKey } from 'store/utils';
import {
  TYPES, SET_EXPLANATION, EXPLANATION, SET_EXPLANATION_OPEN, EXPLANATION_OPEN,
  SELECTED_DRAWING, SET_SELECTED_DRAWING, SET_SECTION, SECTION, SET_CURRENT_INDEX, CURRENT_INDEX,
} from './constants';

const defaultState = { [SECTION]: 'explanation', [CURRENT_INDEX]: 0 };

const behaviors = {
  [TYPES[SET_EXPLANATION]]: assignPayloadToKey(EXPLANATION),
  [TYPES[SET_EXPLANATION_OPEN]]: assignPayloadToKey(EXPLANATION_OPEN),
  [TYPES[SET_SELECTED_DRAWING]]: assignPayloadToKey(SELECTED_DRAWING),
  [TYPES[SET_SECTION]]: assignPayloadToKey(SECTION),
  [TYPES[SET_CURRENT_INDEX]]: assignPayloadToKey(CURRENT_INDEX),
};

export default createReducer(behaviors, defaultState);
