import { createTypes } from 'store/utils';

export const KEY = 'backgrounds';

export const BACKGROUNDS = 'backgrounds';
export const SET_BACKGROUNDS = 'SET_BACKGROUNDS';

export const OPEN = 'open';
export const SET_OPEN = 'SET_OPEN';

export const MyTypesArray = [SET_BACKGROUNDS, SET_OPEN];
export const TYPES = createTypes(KEY, MyTypesArray);
