import { createReducer, assignPayloadToKey } from 'store/utils';
import {
  TYPES,
  DRAWINGS,
  RESET_DRAWINGS,
  BACKGROUND,
  SET_BACKGROUND,
  SIZE,
  SET_SIZE,
  EDIT,
  SET_EDIT,
  ADD_DRAWING,
  REMOVE_DRAWING,
  RESET,
  SET_CHANGE_VISIBILITY,
  CHANGE_VISIBILITY,
  SET_CAN_REMOVE,
  CAN_REMOVE,
  SET_CHANGE_MODE,
  CHANGE_MODE,
  SET_MAX_DRAWINGS,
  MAX_DRAWINGS,
  SET_LINE_DETECTION,
  LINE_DETECTION,
  SET_COLOR,
  COLOR,
  SET_VISIBLE,
  SET_MODE,
  SET_SELECTED_INDEX,
  SELECTED_INDEX,
  UI_DISABLED,
  SET_UI_DISABLED,
  SET_GAP,
  GAP,
  UPDATE_DRAWING,
} from './constants';

const defaultState = {
  [DRAWINGS]: [],
  [BACKGROUND]: null,
  [SIZE]: {},
  [GAP]: 0,
};

const behaviors = {
  [TYPES[ADD_DRAWING]]: (state, { payload }) => ({
    ...state,
    [DRAWINGS]: [...state[DRAWINGS], payload],
  }),
  [TYPES[UPDATE_DRAWING]]: (state, { payload }) => {
    const index = state[DRAWINGS].findIndex(({ id }) => payload.id === id);

    if (index >= 0) {
      const newDrawings = state[DRAWINGS].slice();
      newDrawings.splice(index, 1, payload);
      return ({
        ...state,
        [DRAWINGS]: newDrawings,
      });
    }
    return state;
  },
  [TYPES[REMOVE_DRAWING]]: (state, { payload }) => {
    const drawings = state[DRAWINGS].slice();
    drawings.splice(payload, 1);
    return {
      ...state,
      [DRAWINGS]: drawings,
    };
  },
  [TYPES[SET_VISIBLE]]: (state, { payload }) => {
    const drawings = state[DRAWINGS].map((drawing) => (drawing.id === payload ? { ...drawing, hidden: !drawing.hidden } : drawing));
    return {
      ...state,
      [DRAWINGS]: drawings,
    };
  },
  [TYPES[SET_MODE]]: (state, { payload }) => {
    const drawings = state[DRAWINGS].map((drawing) => (drawing.id === payload ? { ...drawing, mode: (drawing.mode + 1) % 3 } : drawing));
    return {
      ...state,
      [DRAWINGS]: drawings,
    };
  },
  [TYPES[RESET_DRAWINGS]]: (state, { payload }) => ({
    ...state,
    [DRAWINGS]: [],
  }),
  [TYPES[RESET]]: (state, { payload }) => ({
    ...defaultState,
  }),
  [TYPES[SET_BACKGROUND]]: assignPayloadToKey(BACKGROUND),
  [TYPES[SET_SIZE]]: assignPayloadToKey(SIZE),
  [TYPES[SET_EDIT]]: assignPayloadToKey(EDIT),
  [TYPES[SET_COLOR]]: assignPayloadToKey(COLOR),
  [TYPES[SET_GAP]]: assignPayloadToKey(GAP),
  [TYPES[SET_SELECTED_INDEX]]: assignPayloadToKey(SELECTED_INDEX),
  [TYPES[SET_CHANGE_VISIBILITY]]: assignPayloadToKey(CHANGE_VISIBILITY),
  [TYPES[SET_CAN_REMOVE]]: assignPayloadToKey(CAN_REMOVE),
  [TYPES[SET_CHANGE_MODE]]: assignPayloadToKey(CHANGE_MODE),
  [TYPES[SET_MAX_DRAWINGS]]: assignPayloadToKey(MAX_DRAWINGS),
  [TYPES[SET_LINE_DETECTION]]: assignPayloadToKey(LINE_DETECTION),
  [TYPES[SET_UI_DISABLED]]: assignPayloadToKey(UI_DISABLED),
};

export default createReducer(behaviors, defaultState);
