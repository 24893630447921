import { createTypes } from 'store/utils';

export const KEY = 'drawer';

export const DRAWINGS = 'drawings';
export const ADD_DRAWING = 'ADD_DRAWING';
export const UPDATE_DRAWING = 'UPDATE_DRAWING';
export const REMOVE_DRAWING = 'REMOVE_DRAWING';
export const RESET_DRAWINGS = 'RESET_DRAWINGS';

export const BACKGROUND = 'background';
export const SET_BACKGROUND = 'SET_BACKGROUND';

export const SIZE = 'size';
export const SET_SIZE = 'SET_SIZE';

export const EDIT = 'edit';
export const SET_EDIT = 'SET_EDIT';

export const COLOR = 'color';
export const SET_COLOR = 'SET_COLOR';

export const GAP = 'gap';
export const SET_GAP = 'SET_GAP';

export const SELECTED_INDEX = 'selectedIndex';
export const SET_SELECTED_INDEX = 'SET_SELECTED_INDEX';

export const CHANGE_VISIBILITY = 'changeVisibility';
export const SET_CHANGE_VISIBILITY = 'SET_CHANGE_VISIBILITY';

export const CAN_REMOVE = 'canRemove';
export const SET_CAN_REMOVE = 'SET_CAN_REMOVE';

export const CHANGE_MODE = 'changeMode';
export const SET_CHANGE_MODE = 'SET_CHANGE_MODE';

export const MAX_DRAWINGS = 'maxDrawings';
export const SET_MAX_DRAWINGS = 'SET_MAX_DRAWINGS';

export const LINE_DETECTION = 'lineDetection';
export const SET_LINE_DETECTION = 'SET_LINE_DETECTION';

export const UI_DISABLED = 'uiDisabled';
export const SET_UI_DISABLED = 'SET_UI_DISABLED';

export const SET_VISIBLE = 'SET_VISIBLE';
export const SET_MODE = 'SET_MODE';

export const RESET = 'RESET';

export const MyTypesArray = [
  ADD_DRAWING,
  REMOVE_DRAWING,
  RESET_DRAWINGS,
  SET_BACKGROUND,
  SET_SIZE,
  SET_EDIT,
  SET_COLOR,
  RESET,
  SET_CHANGE_VISIBILITY,
  SET_CAN_REMOVE,
  SET_CHANGE_MODE,
  SET_MAX_DRAWINGS,
  SET_LINE_DETECTION,
  SET_VISIBLE,
  SET_MODE,
  SET_SELECTED_INDEX,
  SET_UI_DISABLED,
  SET_GAP,
  UPDATE_DRAWING,
];
export const TYPES = createTypes(KEY, MyTypesArray);
