import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormGroup } from 'components/Menu';

const AdminSettings = ({
  onFetchSessionsList,
  onFetchSessions,
  sessions,
  corpus,
  students,
  sessionsList,
  corpusList,
  onSetCorpus,
}) => {
  useEffect(() => {
    onFetchSessionsList();
    /*
		if (sessions && corpus) {
			onFetchSessions(sessions, corpus, students);
        }
        */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {/*
      <FormGroup label="Sessions">
        <Select options={sessionsList} value={sessions} onChange={onFetchSessions} multiple />
      </FormGroup>
    */}
      <FormGroup label="Corpus">
        <Select options={corpusList} value={corpus} onChange={onSetCorpus} />
      </FormGroup>
    </>
  );
};

AdminSettings.propTypes = {
  onFetchSessionsList: PropTypes.func.isRequired,
  onFetchSessions: PropTypes.func.isRequired,
  sessions: PropTypes.arrayOf(PropTypes.object),
  corpus: PropTypes.object,
  students: PropTypes.arrayOf(PropTypes.object).isRequired,
  sessionsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  corpusList: PropTypes.arrayOf(PropTypes.object),
  onSetCorpus: PropTypes.func.isRequired,
};

AdminSettings.defaultProps = {
  corpusList: undefined,
  corpus: undefined,
  sessions: undefined,
};

export default AdminSettings;
