import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { distance } from '../../utils';

import './heatmap.scss';

const HeatmapDrawingSelectorInfo = ({ drawing }) => {
  const { t } = useTranslation();

  const sessionName = useMemo(
    // () => `${drawing.session.id} - ${drawing.session.name}`, [drawing.session.id, drawing.session.name],
    () => 'bonjour bonsoir', [],
  );

  /*
  const survey = useMemo(
    () => {
      const { survey } = drawing.user;
      return survey ? JSON.stringify(survey) : '-';
    }, [drawing.user],
  );
  */

  return (
    <div className="heatmap-select-info">
      {/*
      <div>
        {t('restitution.drawingSelector.session')}
        :
        {' '}
        {sessionName}
      </div>
      */}

      <div>
        {t('restitution.drawingSelector.user')}
        :
        {' '}
        {drawing.studentId}
      </div>
      {/*
      <div>
        {t('restitution.drawingSelector.survey')}
        :
        {' '}
        <span>{survey}</span>
      </div> */}
      {/*
      <div>
        {t('restitution.drawingSelector.comment')}
        :
        {' '}
        <span>{drawing.comment || '-'}</span>
      </div>
      */}
    </div>
  );
};

const HeatmapDrawingSelector = ({
  drawings, size, onDrawingSelect, selectedDrawing,
}) => {
  const onDrawingClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const rect = e.target.getBoundingClientRect();
    const posX = e.clientX - rect.left;
    const posY = e.clientY - rect.top;

    let minDistance = 100000000;
    let drawing;

    drawings.forEach((item) => {
      const check = ({ x, y }) => {
        const dist = distance(x, y, posX, posY);
        if (dist < minDistance) {
          minDistance = dist;
          drawing = item;
        }
      };

      item.dots.forEach(check);
    });

    if (onDrawingSelect) {
      if (selectedDrawing && selectedDrawing.id === drawing.id) {
        onDrawingSelect();
        return;
      }
      onDrawingSelect(drawing);
    }
  };

  return (
    <>
      {selectedDrawing && <HeatmapDrawingSelectorInfo drawing={selectedDrawing} />}
      <div
        className="drawings heatmap-select"
        onClick={onDrawingClick}
        style={{ width: size.width, height: size.height }}
      />
    </>
  );
};

export default HeatmapDrawingSelector;
