import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import withResize from 'HOC/resize';

const Background = ({
  src, onSizeChange, width, height,
}) => {
  const [style, setStyle] = useState();
  const [imgRatio, setImgRatio] = useState();
  const img = useRef();

  useEffect(
    () => {
      if (src) {
        const loadimg = new Image();
        loadimg.src = src;
        loadimg.onload = () => {
          setImgRatio(loadimg.width / loadimg.height);
        };
      }
    },
    [src],
  );

  useEffect(
    () => {
      if (width && height) {
        const contentRatio = width / height;

        if (contentRatio > imgRatio) {
          setStyle({ width: 'auto', height: '100%' });
        } else {
          setStyle({ width: '100%', height: 'auto' });
        }

        setTimeout(() => {
          onSizeChange({ width: img.current.clientWidth, height: img.current.clientHeight });
        }, 500);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width, height, imgRatio],
  );

  return (
    <div className="background">
      <img ref={img} src={src} style={style} alt="" />
    </div>
  );
};

Background.propTypes = {
  src: PropTypes.string,
  onSizeChange: PropTypes.func.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

Background.defaultProps = {
  width: 0,
  height: 0,
  src: null,
};

export default withResize(Background)(250);
