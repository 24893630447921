import { handleActions } from 'redux-actions';
import type from 'redux-types';

export const createTypes = (reducerKey, types) => type(reducerKey, types);

export const assignPayloadToKey = (key, obj = {}) => (state, { payload }) => ({
	...state,
	[key]: payload,
	...obj,
});

export const assignPayloadToNestedKey = (key, key2, defaultValue) => (state, { payload }) => ({
	...state,
	[key]: {
		...state[key],
		[key2]: payload || defaultValue,
	},
});

export const createReducer = (behaviours, defaultState = {}) => {
	const handler = handleActions(behaviours, defaultState);

	return (state = defaultState, action) => {
		if (action.type in behaviours) {
			return handler(state, action);
		}

		return state;
	};
};

export const createAction = type => payload => ({ type, payload });

export const createActionWithMeta = type => (payload, meta) => ({ type, payload, meta });
