import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import { FormGroup } from 'components/Menu';

const ProfSettings = ({ onFetchBackgroundsSessions, sessions, sessionsList }) => (
  <>
    <FormGroup label="Sessions">
      <Select
        options={sessionsList}
        value={sessions}
        onChange={onFetchBackgroundsSessions}
        multiple
      />
    </FormGroup>
  </>
);

ProfSettings.propTypes = {
  onFetchBackgroundsSessions: PropTypes.func.isRequired,
  sessions: PropTypes.arrayOf(PropTypes.object),
  sessionsList: PropTypes.arrayOf(PropTypes.object),
};

ProfSettings.defaultProps = {
  sessionsList: undefined,
  sessions: undefined,
};

export default ProfSettings;
