import { TYPES, BACKGROUNDS, SET_BACKGROUNDS, SET_OPEN, OPEN } from './constants';
import { createReducer, assignPayloadToKey } from 'store/utils';

const defaultState = { [BACKGROUNDS]: [] };

const behaviors = {
	[TYPES[SET_BACKGROUNDS]]: assignPayloadToKey(BACKGROUNDS),
	[TYPES[SET_OPEN]]: assignPayloadToKey(OPEN),
};

export default createReducer(behaviors, defaultState);
