import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import storage from 'redux-persist/lib/storage/session';

import { KEY as backgroundsKey } from 'components/Backgrounds/redux';
import { KEY as restitutionKey } from 'modules/Restitution/redux';
import { KEY as adminTimelineKey } from 'modules/Admin/Timeline/redux';

import rootReducer from './root.reducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [adminTimelineKey, backgroundsKey, restitutionKey],
};
const middlewares = [thunk];
const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
	  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
	    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
	  })
	  : compose;
const enhancer = composeEnhancers(applyMiddleware(...middlewares));
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(persistedReducer, {}, enhancer);

export const persistor = persistStore(store);
export default store;
