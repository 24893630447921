import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { modes } from 'components/Heatmap';
import Menu, { Button } from 'components/Menu';
import Ratio from 'components/Ratio';
import fileDownload from 'js-file-download';
import {
  getCorpus,
  getResult,
  getSelectedSessions,
  getSettings,
  getSettingsOpen,
  getSlidersOpen,
  getSessions,
  getFile,
} from './redux/selectors';

import Settings from './Settings';
import Sliders from './Sliders';

import {
  setSettingsOpen,
  setSlidersOpen,
  setSelectedSessions,
  share,
  downloadSession,
} from './redux/actions';
import roles from '../../App/roles';
import Session from './Session';

import RestitutionSingle from './RestitutionSingle';
import RestitutionProf from './RestitutionProf';
import RestitutionStudent from './Viewer/index';
import useActions from '../../hooks/useActions';
import './restitution.scss';

export { RestitutionProf, RestitutionSingle, RestitutionStudent };

export const modeOptions = [
  { label: 'restitution.modes.dots', value: modes.DOTS, role: roles.PROF },
  {
    label: 'restitution.modes.lines',
    value: modes.LINES,
    role: roles.STUDENT,
  },
  {
    label: 'restitution.modes.shapes',
    value: modes.SHAPES,
    role: roles.STUDENT,
  },
];

const Restitution = ({
  role,
}) => {
  const corpus = useSelector(getCorpus);
  const result = useSelector(getResult);
  const selectedSessions = useSelector(getSelectedSessions);
  const settings = useSelector(getSettings);
  const settingsOpen = useSelector(getSettingsOpen);
  const slidersOpen = useSelector(getSlidersOpen);
  const sessions = useSelector(getSessions);
  const file = useSelector(getFile);

  const [selectedDrawing, setSelectedDrawing] = useState();
  const [printToggle, setPrintToggle] = useState();

  const onSetSettingsOpen = useActions(setSettingsOpen);
  const onSetSlidersOpen = useActions(setSlidersOpen);
  const onSetSelectedSessions = useActions(setSelectedSessions);
  const onShare = useActions(share);
  const onDownloadSession = useActions(downloadSession);

  useEffect(() => {
    setSelectedDrawing();
  }, [result]);

  const onSettingsOpen = () => {
    onSetSettingsOpen(!settingsOpen);
    onSetSlidersOpen(false);
  };

  const onSlidersOpen = () => {
    onSetSlidersOpen(!slidersOpen);
    onSetSettingsOpen(false);
  };

  const handleShare = () => {
    const sharedSessions = Object.keys(selectedSessions).map((id) => {
      const canvas = document.getElementById(id);

      return {
        id,
        backgroundSrc: corpus.url,
        src: canvas.toDataURL('image/jpeg', 0.1),
        name: result.find((item) => item.id === id).name,
      };
    });

    onShare(sharedSessions);
  };

  const onDownload = () => {
    onDownloadSession(sessions[0].value);
  };

  const onFileDownload = () => {
    const {
      fileName, survey, sessionsUsed, sessionsNotUsed,
    } = file;

    fileDownload(JSON.stringify({ survey, sessionsUsed, sessionsNotUsed }, 0, 4), `${fileName}.json`);
  };

  const noResult = !(result && result.length);
  const sessionsNumber = sessions.length;
  const noSelection = false;

  const onToggleSelection = () => {
    if (noSelection) {
      onSetSelectedSessions(result.reduce((object, item) => ({ ...object, [item.id]: true }), {}));
    } else {
      onSetSelectedSessions({});
    }
  };

  const onPrintToggle = () => {
    setPrintToggle(!printToggle);
    onSetSettingsOpen(false);
    onSetSlidersOpen(false);
  };

  const content = useMemo(() => {
    const onSelect = (id) => {
      if (result.length !== 1) {
        if (selectedSessions[id]) {
          onSetSelectedSessions(
            Object.keys(selectedSessions).reduce((object, key) => {
              if (key !== id) {
                return { ...object, [key]: selectedSessions[key] };
              }
              return object;
            }, {}),
          );
        } else {
          onSetSelectedSessions({ ...selectedSessions, [id]: true });
        }
      }
    };

    let calculatedContent = null;
    if (!noResult) {
      if (result.length === 1) {
        const [{
          id, drawings, name, studentsNumber,
        }] = result;
        calculatedContent = (
          <Session
            {...{
              id,
              onSelect,
              name,
              src: corpus && corpus.url,
              drawings,
              settings: settings[id],
              role,
              clickable: true,
              onDrawingSelect: setSelectedDrawing,
              selectedDrawing,
              studentsNumber,
            }}
          />
        );
      } else {
        calculatedContent = (
          <>
            {result.map(({ id, drawings, name }) => (
              <Ratio
                key={id}
                className={classNames({
                  selected: selectedSessions[id],
                })}
              >
                <Session
                  {...{
                    id,
                    onSelect,
                    name,
                    src: corpus && corpus.url,
                    drawings,
                    settings: settings[id],
                    role,
                    clickable: true,
                    onDrawingSelect: setSelectedDrawing,
                    selectedDrawing,
                  }}
                />
              </Ratio>
            ))}
          </>
        );
      }
    }
    return calculatedContent;
  }, [corpus, noResult, onSetSelectedSessions, result, role, selectedDrawing, selectedSessions, settings]);

  const onPrintClose = () => {
    setPrintToggle(false);
  };

  useEffect(() => {
    const body = document.querySelector('body');
    if (printToggle) {
      body.classList.add('print');
    } else {
      body.classList.remove('print');
    }
  }, [printToggle]);

  return (
    <div className="menu-container restitution">
      <div className="close" onClick={onPrintClose}>
        <i className="la la-times" />
      </div>
      <Menu>
        {/*
        <Button
          icon="download"
          onClick={onDownload}
          disabled={sessionsNumber !== 1}
          role={roles.ADMIN}
        />
        <Button icon="file" onClick={onFileDownload} disabled={!file} role={roles.ADMIN} />
        <Button
          icon={noSelection ? 'plus' : 'minus'}
          onClick={onToggleSelection}
          disabled={(result || []).length < 2}
        />
        <Button
          icon="share"
          onClick={handleShare}
          disabled={noResult || noSelection}
          test={(r) => r === roles.PROF}
        />
          */}
        <Button icon="print" onClick={onPrintToggle} active={printToggle} />
        <Button icon="wrench" onClick={onSettingsOpen} active={settingsOpen} />
        <Button
          className="slides"
          icon="sliders"
          onClick={onSlidersOpen}
          active={slidersOpen}
          disabled={noResult || noSelection}
        />
      </Menu>
      <Settings role={role} />
      <Sliders />
      <div className="sessions">{content}</div>
    </div>
  );
};

Restitution.propTypes = {
  role: PropTypes.number,
};

Restitution.defaultProps = {
  role: roles.NONE,
};

export default Restitution;
