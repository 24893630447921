import React, { useMemo, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import Overlay from '../Overlay';
import { getUser } from '../../App/redux/selectors';
import { nextBackground } from '../../App/redux/actions';

import './nextbackground.scss';
import tokenHandler from '../../utils/tokenHandler';

const HasNext = () => {
  const onNextBackground = useActions(nextBackground);
  const user = useSelector(getUser);
  const { t } = useTranslation();

  const timer = useRef();

  useEffect(() => {
    timer.current = setTimeout(() => {
      onNextBackground();
    }, 3000);
  }, [onNextBackground]);

  const onNext = () => {
    clearTimeout(timer.current);
    onNextBackground();
  };

  return (

    <div className="next-background">
      <p>
        {t('nextBackground.endSession')}
        {' '}
        {user.background.nextIndex + 1}
        {' '}
        / 5
      </p>

      <button type="button" className="button next-background__button" onClick={onNext}>
        <div className="delay-bar">
          <div className="delay-bar__content" />
        </div>
      </button>

    </div>
  );
};

const NoNext = () => {
  useEffect(() => {
    const token = tokenHandler.getToken();
    document.location.href = `/api/moodle/next?token=${token}`;
  }, []);

  return null;
};

const NextBackground = () => {
  const user = useSelector(getUser);

  const displayNext = useMemo(
    () => user.background.nextIndex + 1 <= 5, [user.background.nextIndex],
  );

  return (
    <Overlay open className="end-session">
      {displayNext ? <HasNext /> : <NoNext />}
    </Overlay>
  );
};

export default NextBackground;
