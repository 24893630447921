import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Overlay from 'components/Overlay';

import Menu, { Button } from 'components/Menu';
import { useTranslation } from 'react-i18next';
import {
  getCorpus,
  getResult,
  getSelectedSessions,
  getSettings,
} from './redux/selectors';

import {
  setSlidersOpen, setSelectedSessions, single,
} from './redux/actions';
import Session from './Session';
import { getRestitutionOpen } from '../../App/redux/selectors';
import useActions from '../../hooks/useActions';
import { Help } from '../../components/Overlay';
import Explanation, { useExplanation, useExplanationBackground } from '../../components/Explanation';
import { getCurrentIndex, getExplanationOpen } from '../../components/Explanation/redux/selectors';
import { nextExplanation, prevExplanation } from '../../components/Explanation/redux/actions';
import Information from '../../components/Information';

import './restitution.scss';

const Wrapper = () => {
  const background = useExplanationBackground();
  const explanation = useExplanation();

  const content = useMemo(() => {
    if (background === 'END') {
      return <div>fdsfsdfsdfsdf</div>;
    }
    if (background && explanation) {
      return <RestitutionSingle />;
    }
    return null;
  }, [background, explanation]);

  return content;
};

const RestitutionSingle = () => {
  const corpus = useSelector(getCorpus);
  const settings = useSelector(getSettings);
  const result = useSelector(getResult);
  const selectedSessions = useSelector(getSelectedSessions);
  const restitutionOpen = useSelector(getRestitutionOpen);

  const onSetSlidersOpen = useActions(setSlidersOpen);
  const onSetSelectedSessions = useActions(setSelectedSessions);
  const onSingle = useActions(single);

  const explanationOpen = useSelector(getExplanationOpen);

  const [helpOpen, setHelpOpen] = useState(false);

  const { t } = useTranslation();

  const onNextExplanation = useActions(nextExplanation);
  const onPrevExplanation = useActions(prevExplanation);

  const currentIndex = useSelector(getCurrentIndex);

  useEffect(() => {
    onSingle();
  }, [onSingle]);

  const helpItems = useMemo(
    () => [
      {
        id: 'help1',
        desc: (
          <p>
            <span className="single-help-title">{t('restitution.help.title1')}</span>
            <br />
            <br />
            <span>{t('restitution.help.text1')}</span>
            <br />
            <br />
            <span>
              {t('restitution.help.text2')}
            </span>
          </p>
        ),
      },
    ],
    [t],
  );

  const [confirmOpen, setConfirmOpen] = useState();

  const onNextRestitution = () => {
    if (currentIndex + 1 > 4) {
      setConfirmOpen(true);
    } else {
      onSetSlidersOpen(false);
      onNextExplanation('/moodle/explanation');
    }
  };

  const onSelect = (id) => {
    if (result.length !== 1) {
      if (selectedSessions[id]) {
        onSetSelectedSessions(
          Object.keys(selectedSessions).reduce((object, key) => {
            if (key !== id) {
              return { ...object, [key]: selectedSessions[key] };
            }
            return object;
          }, {}),
        );
      } else {
        onSetSelectedSessions({ ...selectedSessions, [id]: true });
      }
    }
  };

  const onHelpToggle = () => {
    setHelpOpen(!helpOpen);
  };

  const hasResult = useMemo(() => result && result.length, [result]);

  const singleName = useMemo(() => {
    if (settings && settings.user && settings.user.mode) {
      return t(`restitution.single.${settings.user.mode.value}`);
    }
    return '';
  }, [settings, t]);

  const prevClasses = useMemo(() => classNames({ restitution__disabled: currentIndex <= 0 }), [currentIndex]);

  const onBack = () => {
    setConfirmOpen(false);
    onSetSlidersOpen(false);
  };

  const onNext = () => {
    setConfirmOpen(false);
    onSetSlidersOpen(false);
    onNextExplanation('/moodle/explanation');
  };

  return (
    <>
      <Overlay open className="restitution">
        {confirmOpen && (
        <Information title={t('restitution.confirm.title')}>
          <div className="restitution__end">
            <p>{t('restitution.confirm.text1')}</p>
            <p>{t('restitution.confirm.text2')}</p>
            <button type="button" onClick={onBack} className="button restitution__end-button">
              {t('restitution.confirm.backButton')}
            </button>
            <button type="button" onClick={onNext} className="button button restitution__end-button">
              {t('restitution.confirm.nextButton')}
            </button>
          </div>
        </Information>
        )}

        <Help items={helpItems} open={helpOpen} onClose={onHelpToggle} />
        <div className={classNames('restitution', { 'menu-container': !restitutionOpen })}>

          {!explanationOpen && (
          <Menu>
            <Button icon="question" onClick={onHelpToggle} />
            <Button icon="arrow-right" onClick={onNextRestitution} />
            <Button icon="arrow-left" onClick={onPrevExplanation} className={prevClasses} />
          </Menu>
          )}

          {explanationOpen ? <Explanation /> : (
            <>
              {hasResult && (
              <div className="sessions">
                {result.map(({ id, drawings, name }) => (
                  <Session
                    {...{
                      key: id,
                      id,
                      onSelect,
                      name: id === 'user' ? singleName : name,
                      src: corpus && corpus.url,
                      drawings,
                      settings: settings[id],
                    }}
                  />
                ))}
              </div>
              )}
            </>
          )}

        </div>
      </Overlay>
    </>
  );
};

RestitutionSingle.propTypes = {
  slidersOpen: PropTypes.bool.isRequired,
  result: PropTypes.arrayOf(PropTypes.object),
  onSetSlidersOpen: PropTypes.func.isRequired,
  onSetSelectedSessions: PropTypes.func.isRequired,
  onBackground: PropTypes.func.isRequired,
  restitutionOpen: PropTypes.bool,
};

RestitutionSingle.defaultProps = {
  result: undefined,
  restitutionOpen: false,
};

export default Wrapper;
