import React, {
  useRef, useEffect, useMemo, useState,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Selection = ({
  value,
  multiple,
  onSearch,
  onRemove,
  onToggle,
  searching,
  onSearchToggle,
  search,
  noSearch,
  noSelection,
  onListToggle,
}) => {
  const { t } = useTranslation();

  const input = useRef();

  const handleSearch = (e) => {
    onSearch(e.target.value);
  };

  useEffect(
    () => {
      if (searching) {
        input.current.focus();
      }
    },
    [searching],
  );

  const hasValues = useMemo(() => !!(multiple
    && value && value.length && value.length <= 2), [multiple, value]);

  const [placeholder, setPlaceholder] = useState();
  const [hasValue, setHasValue] = useState();

  useEffect(() => {
    let p = t('select.placeholder');
    let v = false;
    if (searching) {
      p = '';
    } else if (value) {
      if (multiple) {
        if (value.length) {
          v = true;
          if (value.length > 2) {
            p = t('select.selectedItems', { value: value.length });
          } else {
            p = '';
          }
        }
      } else {
        v = true;
        p = value.label;
      }
    }
    setPlaceholder(p);
    setHasValue(v);
  }, [multiple, searching, t, value]);

  const showSelection = useMemo(() => multiple && !noSelection, [multiple, noSelection]);

  return (
    <div className={classNames('selection', { 'has-value': hasValue, 'has-values': hasValues })}>
      {showSelection && (
      <i
        className={classNames('opts la', value && value.length ? 'la-minus-square' : 'la-plus-square')}
        onClick={onToggle}
      />
      )}
      {hasValues && (
      <div className="values">
        {value.map((item) => (
          <div
            key={item.value}
            className="value"
            onClick={() => {
							  onRemove(item);
            }}
          >
            <span>{item.label}</span>
            <i className="la la-times" />
          </div>
        ))}
      </div>
      )}
      <input
        ref={input}
        type="text"
        className="search"
        onChange={handleSearch}
        placeholder={placeholder}
        readOnly={!searching}
        value={search}
      />
      {!noSearch && (
      <i className={classNames('searching la la-search', { active: searching })} onClick={onSearchToggle} />
      )}
      <i className="arrow la la-caret-down" onClick={onListToggle} />
    </div>
  );
};

Selection.propTypes = {
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]),
  onSearch: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onToggle: PropTypes.func.isRequired,
  searching: PropTypes.bool.isRequired,
  onSearchToggle: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired,
  noSearch: PropTypes.bool,
  noSelection: PropTypes.bool,
};

Selection.defaultProps = {
  value: undefined,
  multiple: false,
  noSearch: false,
  noSelection: false,
};

export default Selection;
