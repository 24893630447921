import React from 'react';
import Overlay from 'components/Overlay';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const Confirm = ({ open, message, onConfirm }) => {
  const { t } = useTranslation();

  return (
    <Overlay className="confirm" open={open}>
      <div className="message">{message}</div>
      <div className="buttons">
        <button className="button" onClick={() => onConfirm(false)}>
          {t('backgrounds.no')}
        </button>
        <button className="button" onClick={() => onConfirm(true)}>
          {t('backgrounds.yes')}
        </button>
      </div>
    </Overlay>
  );
};

Confirm.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

Confirm.defaultProps = {
  open: false,
};

export default Confirm;
