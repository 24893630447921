import React from 'react';

import './information.scss';

const Information = ({ onNext, title, children }) => (
  <div className="information">
    {title && <h4>{title}</h4>}
    <span>{children}</span>
    {onNext && (
    <button type="button" className="button button__next" onClick={onNext}>
      <i className="la la-arrow-right" />
    </button>
    )}
  </div>
);

export default Information;
