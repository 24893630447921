import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Selection from './Selection';
import Options from './Options';

import './select.scss';

const Select = ({
  multiple,
  grouped,
  value,
  options,
  onChange,
  noSearch,
  noSelection,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [searching, setSearching] = useState(false);
  const [search, setSearch] = useState('');
  const [processedOptions, setProcessedOptions] = useState(options);
  const [valuesMap, setValuesMap] = useState({});
  const timer = useRef();

  useEffect(() => {
    let tmp;

    if (multiple) {
      tmp = (value || []).reduce((result, option) => ({ ...result, [option.value]: true }), {});
    } else {
      tmp = value
        ? { [value.value]: true }
        : {};
    }
    setValuesMap(tmp);
  }, [multiple, value]);

  useEffect(() => {
    setProcessedOptions(options);
  }, [options]);

  const onSearchToggle = () => {
    setSearching(!searching);
    setSearch('');
    onSearch('');
  };

  const onSearch = (search) => {
    setSearch(search);
    if (search) {
      const regex = RegExp(`.*${search}.*`, 'i');
      setProcessedOptions(
        options.filter(({ label }) => regex.test(label)),
      );
    } else {
      setProcessedOptions(options);
    }
  };

  const onSelect = (option) => {
    if (multiple) {
      const tmp = value || [];
      const index = tmp.findIndex((item) => item.value === option.value);

      if (index >= 0) {
        const newValue = tmp.slice();
        newValue.splice(index, 1);
        setValuesMap({ ...valuesMap, [option.value]: false });
        onChange(newValue);
      } else {
        setValuesMap({ ...valuesMap, [option.value]: true });
        onChange([...tmp, option]);
      }
    } else {
      const isSelected = !value || (value && value.value !== option.value);
      setValuesMap({ [option.value]: isSelected });
      onChange(isSelected ? option : undefined);
      setOpen(false);
    }
  };

  const onToggle = () => {
    setOpen(false);
    if (value && value.length) {
      onChange([]);
      setValuesMap({});
    } else {
      onChange(
        grouped ? options.map(({ options }) => options).flat() : options,
      );
      setValuesMap(
        options.reduce((result, option) => ({ ...result, [option.value]: true }), {}),
      );
    }
  };

  const onFocus = () => {
    clearTimeout(timer.current);
    setOpen(true);
  };

  const onBlur = () => {
    timer.current = setTimeout(() => {
      setOpen(false);
      setSearching(false);
      setSearch('');
    }, 100);
  };

  const onListToggle = () => {
    setOpen(!open);
  };

  return (
    <div
      className={classNames('input select', { open })}
      tabIndex="0"
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <Selection
        {...{
          multiple,
          value,
          onSearch,
          onRemove: onSelect,
          onToggle,
          search,
          searching,
          onSearchToggle,
          noSearch,
          noSelection,
          onListToggle,
        }}
      />
      <div className="list-container">
        <div className="list">
          {options && options.length ? (
            grouped ? (
              options.map((group) => (
                <div key={group.id} className="group">
                  <div className="name">{group.label}</div>
                  <Options
                    options={group.options}
                    multiple={multiple}
                    valuesMap={valuesMap}
                    onSelect={onSelect}
                  />
                </div>
              ))
            ) : (
              <Options
                options={processedOptions}
                multiple={multiple}
                valuesMap={valuesMap}
                onSelect={onSelect}
              />
            )
          ) : (
            <div className="no-options">
              {t('select.noOptions')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Select.propTypes = {
  multiple: PropTypes.bool,
  grouped: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  noSearch: PropTypes.bool,
  noSelection: PropTypes.bool,
};

Select.defaultProps = {
  value: undefined,
  grouped: false,
  multiple: false,
  options: undefined,
  noSearch: false,
  noSelection: false,
};

export default Select;
