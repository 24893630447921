import { createAction } from 'store/utils';
import { setSharedDrawings } from 'App/redux/actions';

import {
  TYPES,
  ADD_DRAWING,
  RESET,
  RESET_DRAWINGS,
  SET_BACKGROUND,
  SET_SIZE,
  SET_COLOR,
  SET_EDIT,
  SET_CHANGE_VISIBILITY,
  SET_CAN_REMOVE,
  SET_CHANGE_MODE,
  SET_MAX_DRAWINGS,
  SET_LINE_DETECTION,
  SET_UI_DISABLED,
  SET_VISIBLE,
  SET_MODE,
  REMOVE_DRAWING,
  SET_SELECTED_INDEX,
  SET_GAP,
  UPDATE_DRAWING,
} from './constants';
import { setRestitutionOpen } from '../../../App/redux/actions';

export const addDrawing = createAction(TYPES[ADD_DRAWING]);
export const updateDrawing = createAction(TYPES[UPDATE_DRAWING]);
export const doRemoveDrawing = createAction(TYPES[REMOVE_DRAWING]);
export const doSetBackground = createAction(TYPES[SET_BACKGROUND]);

export const resetDrawings = createAction(TYPES[RESET_DRAWINGS]);
export const setSize = createAction(TYPES[SET_SIZE]);
export const setEdit = createAction(TYPES[SET_EDIT]);
export const setColor = createAction(TYPES[SET_COLOR]);
export const setSelectedIndex = createAction(TYPES[SET_SELECTED_INDEX]);
export const setVisible = createAction(TYPES[SET_VISIBLE]);
export const setMode = createAction(TYPES[SET_MODE]);
export const reset = createAction(TYPES[RESET]);
export const setGap = createAction(TYPES[SET_GAP]);

export const setChangeVisibility = createAction(TYPES[SET_CHANGE_VISIBILITY]);
export const setCanRemove = createAction(TYPES[SET_CAN_REMOVE]);
export const setChangeMode = createAction(TYPES[SET_CHANGE_MODE]);
export const setMaxDrawings = createAction(TYPES[SET_MAX_DRAWINGS]);
export const setLineDetection = createAction(TYPES[SET_LINE_DETECTION]);
export const setUIDisabled = createAction(TYPES[SET_UI_DISABLED]);

export const removeDrawing = (drawing) => async (dispatch) => {
  dispatch(doRemoveDrawing(drawing));
};

export const setBackground = (background) => async (dispatch) => {
  dispatch(doSetBackground(background));
  dispatch(resetDrawings());
  dispatch(setSharedDrawings([]));
  dispatch(setRestitutionOpen(false));
  if (background) {
    dispatch(setMaxDrawings(50));
  }
};
