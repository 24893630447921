import { createTypes } from 'store/utils';

export const KEY = 'explanation';

export const EXPLANATION = 'explanation';
export const SET_EXPLANATION = 'SET_EXPLANATION';

export const EXPLANATION_OPEN = 'explanationOpen';
export const SET_EXPLANATION_OPEN = 'SET_EXPLANATION_OPEN';

export const SELECTED_DRAWING = 'selectedDrawing';
export const SET_SELECTED_DRAWING = 'SET_SELECTED_DRAWING';

export const CURRENT_INDEX = 'currentIndex';
export const SET_CURRENT_INDEX = 'SET_CURRENT_INDEX';

export const SECTION = 'section';
export const SET_SECTION = 'SET_SECTION';

export const MyTypesArray = [
  SET_EXPLANATION,
  SET_EXPLANATION_OPEN, SET_SELECTED_DRAWING, SET_SECTION, SET_CURRENT_INDEX,
];
export const TYPES = createTypes(KEY, MyTypesArray);
