import logger from 'js-logger';

import { createAction } from 'store/utils';
import ajaxHandler from 'network/ajaxHandler';
import { setLoading } from 'App/redux/actions';
import moment from 'moment';

import { getRole } from 'App/redux/selectors';
import roles from 'App/roles';
import store from 'store';
import i18n from 'i18next';
import fileDownload from 'js-file-download';

import { getName } from 'utils';
import {
  TYPES,
  SET_STUDENTS,
  SET_SESSIONS_LIST,
  SET_SESSIONS,
  SET_CORPUS,
  SET_NUMBER,
  SET_SUPERPOSE,
  SET_CORPUS_LIST,
  SET_STUDENTS_LIST,
  SET_DATA,
  SET_RESULT,
  SET_FILE,
  SET_SETTINGS_OPEN,
  SET_SLIDERS_OPEN,
  SET_SELECTED_SESSIONS,
  SET_SETTINGS,
  SET_SURVEY_OPTIONS,
  SET_SURVEY_FILTER,
  SET_SESSIONS_DRAWING,
} from './constants';
import { getSelectedSessions, getSettings, getSurveyFilter } from './selectors';
import { defaultSettings } from './reducer';
import { getGroupId, getUser } from '../../../App/redux/selectors';
import { getBackground } from '../../../components/Drawer/redux/selectors';
import { setRestitutionOpen } from '../../../App/redux/actions';

export const setSessionsList = createAction(TYPES[SET_SESSIONS_LIST]);
export const setSessions = createAction(TYPES[SET_SESSIONS]);
export const setSessionsDrawing = createAction(TYPES[SET_SESSIONS_DRAWING]);
export const setCorpusList = createAction(TYPES[SET_CORPUS_LIST]);
export const doSetCorpus = createAction(TYPES[SET_CORPUS]);
export const setStudentsList = createAction(TYPES[SET_STUDENTS_LIST]);
export const setStudents = createAction(TYPES[SET_STUDENTS]);
export const setNumber = createAction(TYPES[SET_NUMBER]);
export const setSuperpose = createAction(TYPES[SET_SUPERPOSE]);
export const setData = createAction(TYPES[SET_DATA]);
export const setResult = createAction(TYPES[SET_RESULT]);
export const setFile = createAction(TYPES[SET_FILE]);
export const setSettingsOpen = createAction(TYPES[SET_SETTINGS_OPEN]);
export const setSlidersOpen = createAction(TYPES[SET_SLIDERS_OPEN]);
export const setSurveyOptions = createAction(TYPES[SET_SURVEY_OPTIONS]);
export const setSurveyFilter = createAction(TYPES[SET_SURVEY_FILTER]);

export const setSelectedSessions = createAction(TYPES[SET_SELECTED_SESSIONS]);
export const setSettings = createAction(TYPES[SET_SETTINGS]);

export const setSetting = (type, value) => async (dispatch) => {
  const selection = getSelectedSessions(store.getState());
  const settings = getSettings(store.getState());
  if (Object.keys(selection).length) {
    const newSettings = Object.keys(settings).reduce((object, key) => {
      object[key] = selection[key] ? { ...settings[key], [type]: value } : settings[key];
      return object;
    }, {});
    dispatch(setSettings(newSettings));
  }
};

const getStudentsList = (data) => Object.keys(data).map((sessionKey) => ({
  id: sessionKey,
  label: moment(parseInt(sessionKey, 10)).format('DD/MM/YYYY hh:mm:ss'),
  options: data[sessionKey].users.map(({ id }) => ({
    label: id,
    value: id,
  })),
}));

export const setCorpus = (corpus) => async (dispatch) => {
  dispatch(setResult());
  dispatch(doSetCorpus(corpus));
};

export const fetchBackgroundsList = () => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const role = getRole(store.getState());
    const backgrounds = await ajaxHandler.fetch('sessions/backgrounds/list', {
      filtered: role < roles.ADMIN,
    });
    const list = backgrounds.map(({ id, title, src }) => ({ label: title, value: id, src }));

    dispatch(setCorpusList(list));
  } catch (e) {
    logger.warn('Sessions list fetch error ', e.status, e.message);
  }
  dispatch(setLoading(false));
};

export const downloadSession = (sessionId) => async (dispatch) => {
  try {
    const data = await ajaxHandler.get(`session/download?sessionId=${sessionId}`);
    fileDownload(JSON.stringify(data, 0, 4), `${getName('session', parseInt(sessionId))}.json`);
  } catch (e) {
    logger.warn('Session download error ', e.status, e.message);
  }
};

export const fetchBackgroundGroupsList = (corpus) => async (dispatch) => {
  dispatch(setResult());
  dispatch(setLoading(true));
  try {
    const sessions = await ajaxHandler.fetch('sessions/background/groups/list', {
      backgroundId: corpus.value,
    });
    const list = sessions
      .sort((a, b) => -(a.creationDate - b.creationDate))
      .map(({ name, creationDate, id }) => ({
        label: getName(name, creationDate),
        value: id,
      }));

    dispatch(setSessionsList(list));
    dispatch(setSessions());
    dispatch(fetchBackgroundsSessions());
    dispatch(setStudents());
  } catch (e) {
    logger.warn('Sessions corpus list fetch error ', e.status, e.message);
  }
  dispatch(setLoading(false));
};

export const fetchBackgroundsSessions = (sessionValues) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setSessions(sessionValues));
  try {
    const { data } = await ajaxHandler.fetch('sessions/groups', {
      sessionIds: sessionValues.map(({ value }) => value),
    });

    const studentsList = getStudentsList(data);

    dispatch(setStudentsList(studentsList));
    // dispatch(setStudents(studentsList.map(group => group.options).flat()));
    dispatch(setStudents());
    dispatch(setData(data));
  } catch (e) {
    logger.warn('Sessions list fetch error ', e.status, e.message);
  }
  dispatch(setLoading(false));
};

export const fetchSessionsList = () => async (dispatch) => {
  dispatch(setLoading(true));
  /*
  try {
    const sessions = await ajaxHandler.fetch('sessions/groups/list', {});
    const list = sessions
      .sort((a, b) => -(a.creationDate - b.creationDate))
      .map(({ name, creationDate, id }) => ({
        label: getName(name, creationDate),
        value: id,
      }));

    dispatch(setSessionsList(list));
  } catch (e) {
    logger.warn('Sessions list fetch error ', e.status, e.message);
  }
  */
  dispatch(setSessionsList([]));
  dispatch(setLoading(false));
};

export const fetchSessions = (
  sessionValues,
  selectedCorpus,
  selectedStudents,
) => async (dispatch) => {
  dispatch(setLoading(true));
  dispatch(setSessions(sessionValues));

  try {
    const { data, corpus } = await ajaxHandler.fetch('sessions/groups', {
      sessionIds: sessionValues.map(({ value }) => value),
    });

    dispatch(setCorpusList(corpus.map(({ id, title, src }) => ({ label: title, value: id, src }))));
    dispatch(setCorpus());

    const studentsList = getStudentsList(data);

    dispatch(setStudentsList(studentsList));
    dispatch(setStudents(studentsList.map((group) => group.options).flat()));
    dispatch(setData(data));
  } catch (e) {
    logger.warn('Sessions list fetch error ', e.status, e.message);
  }
  dispatch(setLoading(false));
};

export const generate = (corpus, number, studentIds) => async (dispatch) => {
  dispatch(setResult());

  const answers = getSurveyFilter(store.getState());
  const backgroundId = corpus.value;

  const { drawings, studentsNumber } = await ajaxHandler.fetch('moodle/restitution/drawings', {
    backgroundId,
    studentIds: studentIds && studentIds.map(({ value }) => value),
    answers: Object.keys(answers).reduce((acc, key) => {
      const item = answers[key];
      return item && item.length ? {
        ...acc,
        [key]: item.map(({ value }) => value),
      } : acc;
    }, {}),
  });

  const result = [
    {
      id: 'sessions',
      name: i18n.t('restitution.single.drawings'),
      drawings,
      studentsNumber,
    },
  ];

  if (drawings) {
    if (number !== 20) {
      const temp = drawings.reduce((acc, drawing) => {
        if (!acc[drawing.studentId]) {
          return {
            ...acc,
            [drawing.studentId]: [drawing],
          };
        }
        return {
          ...acc,
          [drawing.studentId]: [...acc[drawing.studentId], drawing],
        };
      }, {});

      result[0].drawings = [].concat(...Object.keys(temp).map(
        (key) => temp[key].sort((a, b) => a.startDate - b.startDate).slice(0, number),
      ));
    }

    const settings = result.reduce((object, item) => ({ ...object, [item.id]: defaultSettings }),
      {});

    const selectedSessions = result.reduce((object, item) => ({ ...object, [item.id]: true }),
      {});

    dispatch(setSelectedSessions(selectedSessions));
    dispatch(setSettings(settings));
    dispatch(setResult(result));
  }

  dispatch(setData(result[0].drawings));

  /*

  let result = [];
  if (data && corpus) {
    const used = {};
    const unused = {};

    Object.keys(data).forEach((key) => {
      const session = data[key];
      const { name, creationDate } = session;
      const { users } = session;
      const displayedSession = { drawings: [], id: key, name: getName(name, creationDate) };

      const filterFunction = (user) => {
        const filterKeys = Object.keys(surveyFilter);
        if (!students.find((item) => item.value === user.id)) {
          return false;
        }
        if (filterKeys.length) {
          if (!user.survey) {
            return false;
          }

          for (let i = 0; i < filterKeys.length; i++) {
            const key = filterKeys[i];
            const values = surveyFilter[key];
            if (values && values.length) {
              const userValue = user.survey[key];
              if (Array.isArray(userValue)) {
                if (!userValue.find((v) => values.find((t) => t.value === v))) {
                  return false;
                }
              } else if (!values.find((v) => v.value === userValue)) {
                return false;
              }
            }
          }
        }
        return true;
      };
      */

  /*
      users = users.filter(filterFunction);

      users.forEach((user) => {
        if (user.drawings) {
          const drawings = Object.keys(user.drawings)
            .filter((drawingsKey) => drawingsKey.split('_')[0] === corpus.value)
            .map((drawingsKey) => user.drawings[drawingsKey].slice(0, number))
            .flat()
            .filter((item) => !item.deleted);

          displayedSession.drawings.push(...drawings);
        }
      });
      */

  /*
      users.forEach((user) => {
        if (filterFunction(user) && user.drawings) {
          if (!used[key]) {
            used[key] = { name: session.name, users: [] };
          }
          used[key].users.push(user);

          const drawings = Object.keys(user.drawings)
            .filter((drawingsKey) => drawingsKey.split('_')[0] === corpus.value)
            .map((drawingsKey) => user.drawings[drawingsKey].slice(0, number))
            .flat()
            .filter((item) => !item.deleted);

          displayedSession.drawings.push(
            ...drawings.map((drawing) => ({ ...drawing, session, user })),
          );
        } else {
          if (!unused[key]) {
            unused[key] = { name: session.name, users: [] };
          }
          unused[key].users.push(user);
        }
      });

      // session.id = key;
      result.push(displayedSession);
      // dispatch(setSettingsOpen(false));
    });

    const fileName = `${corpus.label}_${moment().format('YYYYMMDD')}`;

    if (superpose) {
      result = [
        {
          id: 'superpose',
          name: `${result.length} session(s)`,
          drawings: result.reduce((acc, session) => acc.concat(session.drawings || []), []),
        },
      ];
    }

    const settings = result.reduce((object, item) => {
      object[item.id] = defaultSettings;
      return object;
    }, {});

    const selectedSessions = result.reduce((object, item) => ({ ...object, [item.id]: true }), {});

    dispatch(setSelectedSessions(selectedSessions));
    dispatch(setSettings(settings));
    dispatch(setResult(result));
    dispatch(setFile({
      fileName, survey: surveyFilter, sessionsUsed: used, sessionsNotUsed: unused,
    }));
  } */
};

export const shareDrawing = (drawing) => async () => {
  const groupId = getGroupId(store.getState());
  ajaxHandler.send('sessions/drawing/share', { groupId, drawing });
};

export const share = (sessions) => async () => {
  const groupId = getGroupId(store.getState());
  ajaxHandler.send('sessions/share', { groupId, sessions });
};

export const showExplanation = () => async () => {
  const groupId = getGroupId(store.getState());
  ajaxHandler.send('sessions/explanation/show', { groupId });
};

export const hideExplanation = () => async () => {
  const groupId = getGroupId(store.getState());
  ajaxHandler.send('sessions/explanation/hide', { groupId });
};

export const selectExplanationDrawing = (drawing) => async () => {
  const groupId = getGroupId(store.getState());
  ajaxHandler.send('sessions/explanation/select', { groupId, drawing });
};

export const selectExplanationSection = (section) => async () => {
  const groupId = getGroupId(store.getState());
  ajaxHandler.send('sessions/explanation/section', { groupId, section });
};

// TODO faire la même chose pour historienne
export const single = () => async (dispatch) => {
  dispatch(setLoading(true));

  const background = getBackground(store.getState());
  const user = getUser(store.getState());

  dispatch(setCorpus(background));

  try {
    const data = await ajaxHandler.fetch('moodle/sessions/single', {
      backgroundId: background.id,
      sessionId: getGroupId(store.getState()),
    });

    dispatch(setResult());

    let result = [];

    if (data) {
      /*
      const userDrawings = data.reduce((acc, item) => {
        if (item.student_fk === id) {
          return acc.concat(item.drawings || []);
        }
      }, []);
      */

      /*
      result.push({
        id: 'rere',
        name: 'fsdfsfsd',
        drawings: data,
      });
      */

      /*
      Object.keys(data).forEach((key) => {
        const { name, creationDate } = data[key];
        const { users } = data[key];
        const session = { drawings: [], id: key, name: getName(name, creationDate) };

        users.forEach((user) => {
          if (user.drawings) {
            const drawings = Object.keys(user.drawings)
              .filter((drawingsKey) => drawingsKey.split('_')[0] === background.id)
              .map((drawingsKey) => user.drawings[drawingsKey].slice())
              .flat()
              .filter((item) => !item.deleted);

            session.drawings.push(...drawings);
          }
        });
        result.push(session);
      });

      */

      const userDrawings = data.filter((item) => item.student_fk === user.id);
      const otherDrawings = data.filter((item) => item.student_fk !== user.id);

      result = [
        {
          id: 'user',
          name: i18n.t('restitution.single.drawings'),
          drawings: userDrawings,
        },
        {
          id: 'superpose',
          name: i18n.t('restitution.otherUsers'),
          drawings: otherDrawings,
        },
      ];

      const settings = result.reduce((object, item) => ({ ...object, [item.id]: defaultSettings }),
        {});

      const selectedSessions = result.reduce((object, item) => ({ ...object, [item.id]: true }),
        {});

      dispatch(setSelectedSessions(selectedSessions));
      dispatch(setSettings(settings));
      dispatch(setResult(result));
    }

    dispatch(setData(data));
  } catch (e) {
    logger.warn('Sessions list fetch error ', e.status, e.message);
  }
  dispatch(setLoading(false));
};

export const prof = ({ id, title, src }) => async (dispatch) => {
  const corpus = { label: title, value: id, src };
  const groupId = getGroupId(store.getState());
  ajaxHandler.send('group/background/end', { groupId });
  dispatch(setCorpus(corpus));
  dispatch(setData());
  dispatch(setResult());
  dispatch(fetchBackgroundGroupsList(corpus));
  dispatch(setRestitutionOpen(true));
};

export const fetchSurveyOptions = (lang) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const result = await ajaxHandler.file(`surveys/${lang}.json`);

    dispatch(
      setSurveyOptions(
        result.q.map(({ id, text, val }) => ({
          id,
          text,
          options: val.map(({ id, text }) => ({ label: text, value: id })),
        })),
      ),
    );
  } catch (e) {
    logger.warn('Questions fetch error ', e.status, e.message);
  }
  dispatch(setLoading(false));
};
