import React, { useMemo } from 'react';
import Heatmap from 'components/Heatmap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import roles from '../../App/roles';
import { getNumber, getSurveyFilter, getSurveyOptions } from './redux/selectors';

const SessionTitle = ({ id }) => {
  const surveyOptions = useSelector(getSurveyOptions);

  const title = useMemo(() => surveyOptions && surveyOptions.find(
    (item) => id === item.id,
  ).text, [id, surveyOptions]);

  return <b>{title}</b>;
};

const SessionAnswer = ({ label, value }) => (
  <li>
    {label}
    {' '}
    (
    {value}
    )
  </li>
);

const SessionSurvey = () => {
  const surveyFilter = useSelector(getSurveyFilter);
  const drawingsNumber = useSelector(getNumber);

  return (
    <div className="session-survey">
      <b>
        Succession de tracés par utilisateur:
        {' '}
        {drawingsNumber}
      </b>
      <br />
      <br />
      {Object.keys(surveyFilter).map((key) => (
        <div key={key}>
          <SessionTitle id={key} />
          <ul>
            {surveyFilter[key].map(({ label, value }) => (
              <SessionAnswer {...{ label, value }} />
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

const Session = ({
  id, onSelect, src, drawings,
  settings, role, clickable, onDrawingSelect, selectedDrawing, studentsNumber,
}) => {
  const title = useMemo(() => {
    const tmp = [];

    if (role === roles.ADMIN) {
      tmp.push(`${drawings.length} tracé(s)`);
      tmp.push(`${studentsNumber} utilisateurs`);
      /*
      tmp.push(t('restitution.drawingsNumber', { number }));

      const keys = surveyFilter && Object.keys(surveyFilter);
      if (keys && keys.length) {
        const surveyFilterValues = keys.reduce((acc, key) => {
          if (surveyFilter[key].length) {
            return [...acc, ...surveyFilter[key].map(({ value }) => value)];
          }
          return acc;
        }, []);
        tmp.push(surveyFilterValues.join('-'));
      }
      */
    }

    return tmp.join(' - ');
  }, [drawings.length, role, studentsNumber]);

  const handleDrawingSelect = (drawing) => {
    if (drawing) {
      onDrawingSelect({ ...drawing, sessionId: id });
    } else {
      onDrawingSelect();
    }
  };

  return (
    <div
      className="session"
      onClick={() => { onSelect && onSelect(id); }}
    >
      <div className="name">
        {title}
      </div>
      <div className="session-container">
        <SessionSurvey settings={settings} />
        <Heatmap
          id={id}
          src={src}
          drawings={drawings}
          {...settings}
          clickable={clickable}
          onDrawingSelect={handleDrawingSelect}
          selectedDrawing={selectedDrawing}
        />
      </div>
    </div>
  );
};

Session.propTypes = {
  id: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  drawings: PropTypes.arrayOf(PropTypes.object).isRequired,
  settings: PropTypes.object.isRequired,
};

export default Session;
