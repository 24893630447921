import React, {
  useEffect, useState, useRef, useMemo,
} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import './restitution.scss';
import { useTranslation } from 'react-i18next';
import { FormGroup } from 'components/Menu';
import RCSlider from 'rc-slider';
import { modes } from 'components/Heatmap';
import Select from 'components/Select';
import { modeOptions } from 'modules/Restitution';
import {
  getSlidersOpen,
  getSettings,
  getSelectedSessions,
} from './redux/selectors';
import { setSlidersOpen, setSetting } from './redux/actions';
import {
  MODE, LINE_WIDTH, BLUR, VALUE,
} from './redux/constants';
import { defaultSettings } from './redux/reducer';
import { getRole } from '../../App/redux/selectors';

const Slider = ({
  min, max, value, onChange, label, disabled,
}) => {
  const [_value, setValue] = useState(value);
  const timer = useRef();

  useEffect(() => {
    setValue(value);
  }, [value]);

  const handleChange = (val) => {
    setValue(val);
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      onChange(val);
    }, 250);
  };

  return (
    <div className={classNames('sl-container', { disabled })}>
      <div className="value">{_value}</div>
      <RCSlider
        vertical
        {...{
          min, max, value: _value, onChange: handleChange,
        }}
      />
      <div className="label">{label}</div>
    </div>
  );
};

Slider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

Slider.defaultProps = {
  disabled: false,
};

const Sliders = ({
  slidersOpen,
  onSetSlidersOpen,
  onSetSetting,
  settings,
  selectedSessions,
  role,
}) => {
  const { t } = useTranslation();
  const [slidersSettings, setSlidersSettings] = useState(defaultSettings);

  useEffect(() => {
    if (Object.keys(selectedSessions).length) {
      setSlidersSettings(
        settings[Object.keys(selectedSessions)[0]] || defaultSettings,
      );
    }
  }, [selectedSessions, settings]);

  const options = useMemo(
    () => modeOptions
      .filter((item) => role >= item.role)
      .map(({ label, value }) => ({
        label: i18n.t(label),
        value,
      })),
    [role],
  );

  return (
    <div
      className={classNames('menu-container settings rendering', {
        open: slidersOpen,
      })}
    >
      {/*
			<Menu open>
				<Button onClick={onClose} icon="close" />
			</Menu>
            */}
      <div className="settings-content">
        <FormGroup label={t('restitution.sliders.rendering')}>
          <Select
            options={options}
            value={slidersSettings.mode}
            onChange={(value) => {
              onSetSetting(MODE, value);
            }}
            noSearch
            translate
          />
        </FormGroup>
        <div className="sliders">
          <Slider
            min={1}
            max={50}
            value={slidersSettings.lineWidth}
            onChange={(value) => {
              onSetSetting(LINE_WIDTH, value);
            }}
            label={t('restitution.sliders.lineWidth')}
            disabled={
                            slidersSettings.mode
                            && slidersSettings.mode.value === modes.SHAPES
                        }
          />
          <Slider
            min={0}
            max={50}
            value={slidersSettings.blur}
            onChange={(value) => {
              onSetSetting(BLUR, value);
            }}
            label={t('restitution.sliders.blur')}
          />
          <Slider
            min={1}
            max={100}
            value={slidersSettings.value}
            onChange={(value) => {
              onSetSetting(VALUE, value);
            }}
            label={t('restitution.sliders.value')}
          />
        </div>
      </div>
    </div>
  );
};

Sliders.propTypes = {
  slidersOpen: PropTypes.bool,
  onSetSlidersOpen: PropTypes.func.isRequired,
  onSetSetting: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  selectedSessions: PropTypes.object.isRequired,
  role: PropTypes.number.isRequired,
};

Sliders.defaultProps = {
  slidersOpen: false,
};

const mapStateToProps = (state) => ({
  slidersOpen: getSlidersOpen(state),
  settings: getSettings(state),
  selectedSessions: getSelectedSessions(state),
  role: getRole(state),
});

const mapDispatchToProps = (dispatch, props) => bindActionCreators(
  {
    onSetSlidersOpen: setSlidersOpen,
    onSetSetting: setSetting,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Sliders);
