import { TYPES, CONNECTED, SET_CONNECTED, QUEUE, SET_QUEUE } from './constants';
import { createReducer, assignPayloadToKey } from 'store/utils';

const defaultState = { [CONNECTED]: false, [QUEUE]: [] };

const behaviors = {
	[TYPES[SET_CONNECTED]]: assignPayloadToKey(CONNECTED),
	[TYPES[SET_QUEUE]]: assignPayloadToKey(QUEUE),
};

export default createReducer(behaviors, defaultState);
