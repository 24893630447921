import React, { useMemo } from 'react';
import Overlay from 'components/Overlay';
import { Button } from 'components/Menu';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const HelpItem = ({ icon, desc, className }) => {
  const classes = useMemo(() => classNames('item', { 'no-icon': !icon }), [icon]);

  return (
    <div key={icon} className={classes}>
      {icon && <Button icon={icon} className={className} />}
      <div className="desc">{desc}</div>
    </div>
  );
};

const Help = ({ items, open, onClose }) => (
  <Overlay className="help" open={open}>
    <div className="items">
      {items.map((item) => <HelpItem key={item.id} {...item} />)}
    </div>
    <button type="button" className="button button__next overlay__close" onClick={onClose}>
      <i className="la la-times" />
    </button>
  </Overlay>
);

Help.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Help;
