import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { FormGroup } from 'components/Menu';
import Slider from 'rc-slider';
import roles from 'App/roles';

import { useTranslation } from 'react-i18next';
import StudentsSelect from 'components/StudentsSelect';
import {
  fetchSessionsList,
  fetchSessions,
  setSessions,
  setCorpus,
  setStudents,
  setNumber,
  setSuperpose,
  generate,
  fetchBackgroundsList,
  fetchBackgroundsSessions,
  fetchBackgroundGroupsList,
  setData,
  setResult,
} from './redux/actions';
import {
  getStudents,
  getSessionsList,
  getSessions,
  getCorpus,
  getNumber,
  getSuperpose,
  getCorpusList,
  getStudentsList,
  getData,
  getSettingsOpen,
} from './redux/selectors';

import AdminSettings from './AdminSettings';
import ProfSettings from './ProfSettings';
import SurveySettings from './SurveySettings';
import { getRole } from '../../App/redux/selectors';
import useActions from '../../hooks/useActions';

import './restitution.scss';

const Settings = () => {
  const { t } = useTranslation();

  const sessionsList = useSelector(getSessionsList);
  const sessions = useSelector(getSessions);
  const corpusList = useSelector(getCorpusList);
  const corpus = useSelector(getCorpus);
  const studentsList = useSelector(getStudentsList);
  const students = useSelector(getStudents);
  const number = useSelector(getNumber);
  const superpose = useSelector(getSuperpose);
  const data = useSelector(getData);
  const settingsOpen = useSelector(getSettingsOpen);
  const role = useSelector(getRole);

  const onFetchBackgroundsList = useActions(fetchBackgroundsList);
  const onFetchBackgroundGroupsList = useActions(fetchBackgroundGroupsList);

  const onFetchSessionsList = useActions(fetchSessionsList);

  const onFetchBackgroundsSessions = useActions(fetchBackgroundsSessions);

  const onFetchSessions = useActions(fetchSessions);
  const onSetCorpus = useActions(setCorpus);
  const onSetStudents = useActions(setStudents);
  const onSetNumber = useActions(setNumber);
  const onSetSuperpose = useActions(setSuperpose);
  const onGenerate = useActions(generate);
  const onSetData = useActions(setData);
  const onSetResult = useActions(setResult);

  const [currentStudents, setCurrentStudents] = useState();

  const handleGenerate = () => {
    onGenerate(corpus, number, currentStudents);
  };

  const isDisabled = !corpus;

  return (
    <div className={classNames('menu-container settings', { open: settingsOpen })}>
      <div className="settings-content">
        {role >= roles.ADMIN ? (
          <AdminSettings
            {...{
              onFetchSessionsList,
              onFetchSessions,
              sessions,
              corpus,
              students,
              sessionsList,
              corpusList,
              onSetCorpus,
            }}
          />
        ) : (
          <ProfSettings
            {...{
              onFetchBackgroundsList,
              onFetchBackgroundGroupsList,
              onFetchBackgroundsSessions,

              sessions,
              corpus,
              students,
              sessionsList,
              corpusList,
              onSetCorpus,
              onSetData,
              onSetResult,
            }}
          />
        )}
        {/*
        <FormGroup label="Etudiants">
          <Select
            options={studentsList}
            value={students}
            onChange={onSetStudents}
            multiple
            grouped
          />
        </FormGroup>
        */}
        <FormGroup label={`Nombre de tracés: ${number}`}>
          <Slider min={0} max={20} value={number} onChange={onSetNumber} />
        </FormGroup>

        <FormGroup label="Utilisateurs">
          <StudentsSelect value={currentStudents} onChange={setCurrentStudents} />
        </FormGroup>
        {/*
        <FormGroup label="Rendu" className="rendering">
          <label>{t('restitution.settings.compare')}</label>
          <Switch onChange={onSetSuperpose} checked={superpose} />
          <label>{t('restitution.settings.superpose')}</label>
        </FormGroup>
        */}
        {role >= roles.ADMIN && <SurveySettings />}

        <button type="button" className="button generate" onClick={handleGenerate} disabled={isDisabled}>
          {t('restitution.settings.generate')}
        </button>
      </div>
    </div>
  );
};

export default Settings;
