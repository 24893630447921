import Select from 'react-select';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useActions from 'hooks/useActions';
import { getLoading } from 'App/redux/selectors';
import { fetchGroupsList } from 'modules/Admin/Timeline/redux/actions';
import {
  getGroupsList,
} from 'modules/Admin/Timeline/redux/selectors';

const useGroupsList = () => {
  const groupsList = useSelector(getGroupsList);
  const loading = useSelector(getLoading);

  const onFetchGroupsList = useActions(fetchGroupsList);

  useEffect(() => {
    if (!groupsList && !loading) {
      onFetchGroupsList();
    }
  }, [groupsList, loading, onFetchGroupsList]);

  return groupsList;
};

const StudentsSelect = ({ value, onChange }) => {
  const groupsList = useGroupsList();

  return <Select options={groupsList} value={value} onChange={onChange} isMulti />;
};

export default StudentsSelect;
