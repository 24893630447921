import io from 'socket.io-client';

class SocketHandler {
  connect(context) {
    this.socket = io.connect();
  }

  listen(event, handler) {
    this.socket.on(event, handler);
  }

  stop(event, handler) {
    this.socket.removeListener(event, handler);
  }

  join(room) {
    this.socket.emit('join', room);
  }

  emit(room, event, data) {
    this.socket.emit(event, data);
  }
}

export default new SocketHandler();
