import {
  KEY,
  DRAWINGS,
  BACKGROUND,
  SIZE,
  EDIT,
  CHANGE_VISIBILITY,
  CAN_REMOVE,
  CHANGE_MODE,
  MAX_DRAWINGS,
  LINE_DETECTION,
  COLOR,
  SELECTED_INDEX,
  UI_DISABLED,
  GAP,
} from './constants';

export const getDrawings = (state) => state[KEY][DRAWINGS];
export const getBackground = (state) => state[KEY][BACKGROUND];
export const getSize = (state) => state[KEY][SIZE];
export const getEdit = (state) => state[KEY][EDIT];
export const getColor = (state) => state[KEY][COLOR];
export const getSelectedIndex = (state) => state[KEY][SELECTED_INDEX];

export const getChangeVisibility = (state) => state[KEY][CHANGE_VISIBILITY];
export const getCanRemove = (state) => state[KEY][CAN_REMOVE];
export const getChangeMode = (state) => state[KEY][CHANGE_MODE];
export const getMaxDrawings = (state) => state[KEY][MAX_DRAWINGS];
export const getLineDetection = (state) => state[KEY][LINE_DETECTION];
export const getUIDisabled = (state) => state[KEY][UI_DISABLED];
export const getGap = (state) => state[KEY][GAP];
