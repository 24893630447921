import { combineReducers } from 'redux';
import appReducer, { KEY as appKey } from 'App/redux';
import networkReducer, { KEY as networkKey } from 'network/redux';
import drawerReducer, { KEY as drawerKey } from 'components/Drawer/redux';
import surveyReducer, { KEY as surveyKey } from 'modules/Survey/redux';
import backgroundsReducer, { KEY as backgroundsKey } from 'components/Backgrounds/redux';
import restitutionReducer, { KEY as restitutionKey } from 'modules/Restitution/redux';
import adminReducer, { KEY as adminKey } from 'modules/Admin/redux';
import explanationReducer, { KEY as explanationKey } from 'components/Explanation/redux';
import adminBackgroundsReducer, {
  KEY as adminBackgroundsKey,
} from 'modules/Admin/Backgrounds/redux';
import adminTimelineReducer, { KEY as adminTimelineKey } from 'modules/Admin/Timeline/redux';

export default combineReducers({
  [appKey]: appReducer,
  [networkKey]: networkReducer,
  [drawerKey]: drawerReducer,
  [backgroundsKey]: backgroundsReducer,
  [surveyKey]: surveyReducer,
  [restitutionKey]: restitutionReducer,
  [adminKey]: adminReducer,
  [adminBackgroundsKey]: adminBackgroundsReducer,
  [adminTimelineKey]: adminTimelineReducer,
  [explanationKey]: explanationReducer,
});
