import { createAction } from 'store/utils';

import { secure } from 'modules/Admin/redux/actions';
import { getName } from 'utils';
import {
  TYPES,
  SET_BACKGROUNDS,
  SET_GROUPS_LIST,
  SET_GROUP,
  SET_BACKGROUND,
  SET_DATA,
} from './constants';

export const setGroupsList = createAction(TYPES[SET_GROUPS_LIST]);
export const setBackgrounds = createAction(TYPES[SET_BACKGROUNDS]);
export const setBackground = createAction(TYPES[SET_BACKGROUND]);
export const setGroup = createAction(TYPES[SET_GROUP]);
export const setData = createAction(TYPES[SET_DATA]);

export const fetchGroupsList = () => async (dispatch) => {
  const result = await secure('moodle/timeline/users');

  const groupsList = result ? result
    .sort((a, b) => -(a.id - b.id))
    .map(({
      id, ...other
    }) => ({
      label: id,
      value: id,
      ...other,
    })) : [];

  if (groupsList) {
    dispatch(
      setGroupsList(
        groupsList,
      ),
    );
  }
};

export const fetchGroupData = (studentIds, backgroundId) => async (dispatch) => {
  if (studentIds && studentIds.length && backgroundId) {
    const result = await secure('moodle/timeline/data', { studentIds, backgroundId });

    dispatch(setData(result));

    /*
    const { value, backgrounds } = group;
    dispatch(setGroup(group));
    dispatch(setBackground());
    const data = await secure('admin/group/data', { id: value, backgrounds });
    if (data) {
      dispatch(setData(data.group));
      dispatch(
        setBackgrounds(
          data.backgrounds.map(({ id, title, src }) => ({ label: title, value: id, src })),
        ),
      );
    }
    */
  }
};
