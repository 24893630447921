import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './ratio.scss';

const Ratio = ({ children, className, style, onClick }) => (
	<div className={classNames('ratio', className)} onClick={onClick}>
		<div className="ratio-container">
			<div className="ratio-square" style={style}>
				{children}
			</div>
		</div>
	</div>
);

Ratio.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	className: PropTypes.string,
	style: PropTypes.object.isRequired,
	onClick: PropTypes.func,
};

Ratio.defaultProps = {
	children: null,
	onClick: undefined,
	className: '',
};

export default Ratio;
