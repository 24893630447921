import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Background from './Background';

import './slate.scss';

const Slate = ({
  src, className, onClick, children, onSizeChange,
}) => {
  const drawer = useRef();
  const centerer = useRef();
  const [center, setCenter] = useState();

  const handleSizeChange = (size) => {
    if (size.width && size.height) {
      setCenter({
        left: (drawer.current.offsetWidth - size.width) / 2,
        top: (drawer.current.offsetHeight - size.height) / 2,
      });

      onSizeChange(size);
    }
  };

  return (
    <div className={classNames('slate', className)} ref={drawer} onClick={onClick}>
      <div className="centerer" style={center} ref={centerer}>
        <div className="slate-content">{children}</div>
        <Background src={src} onSizeChange={handleSizeChange} />
      </div>
    </div>
  );
};

Slate.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onSizeChange: PropTypes.func.isRequired,
};

Slate.defaultProps = {
  className: '',
  onClick: undefined,
};

export default Slate;
