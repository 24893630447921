import {
  KEY,
  ROLE,
  GROUP_ID,
  USER,
  LOADING,
  LANG,
  LANGS_OPEN,
  GROUP_CLOSED,
  SHARED_DRAWINGS,
  RESTITUTION_OPEN,
  DISPLAY_SURVEY,
  ENDING_SURVEY,
  DISPLAY_TUTORIAL,
  TUTORIAL_BACKGROUND, DISPLAY_DRAWING_COMMENT,
} from './constants';

export const getUser = (state) => state[KEY][USER];
export const getLoading = (state) => state[KEY][LOADING];
export const getRole = (state) => state[KEY][ROLE];
export const getGroupId = (state) => state[KEY][GROUP_ID];
export const getLang = (state) => state[KEY][LANG];
export const getLangsOpen = (state) => state[KEY][LANGS_OPEN];
export const getGroupClosed = (state) => state[KEY][GROUP_CLOSED];
export const getSharedDrawings = (state) => state[KEY][SHARED_DRAWINGS];
export const getRestitutionOpen = (state) => state[KEY][RESTITUTION_OPEN];
export const getDisplaySurvey = (state) => state[KEY][DISPLAY_SURVEY];
export const getEndingSurvey = (state) => state[KEY][ENDING_SURVEY];
export const getDisplayTutorial = (state) => state[KEY][DISPLAY_TUTORIAL];
export const getDisplayDrawingComment = (state) => state[KEY][DISPLAY_DRAWING_COMMENT];
export const getTutorialBackground = (state) => state[KEY][TUTORIAL_BACKGROUND];
