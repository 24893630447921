import { createAction } from 'store/utils';
import ajaxHandler from 'network/ajaxHandler';
import store from 'store';

import { setLoading, setRole } from 'App/redux/actions';
import roles from 'App/roles';
import {
  TYPES, SET_TOKEN, SET_ERROR, SET_SETTINGS,
} from './constants';
import { getToken } from './selectors';

export const setToken = createAction(TYPES[SET_TOKEN]);
export const setError = createAction(TYPES[SET_ERROR]);
export const doSetSettings = createAction(TYPES[SET_SETTINGS]);

export const secure = async (route, params = {}) => {
  const token = getToken(store.getState());
  if (token) {
    const response = await ajaxHandler.fetch(route, { ...params, token });
    if (!response.error) {
      return response;
    }
  }

  store.dispatch(setToken(''));
  store.dispatch(setRole(roles.NONE));
};

export const fetchAdmin = (password) => async (dispatch) => {
  dispatch(setLoading(true));
  const token = await ajaxHandler.fetch('admin/login', { password });
  if (token) {
    dispatch(setRole(roles.ADMIN));
    dispatch(setToken(token));
  } else {
    dispatch(setError(true));
  }

  dispatch(setLoading(false));
};

export const fetchSettings = () => async (dispatch) => {
  const settings = await secure('admin/settings');
  dispatch(doSetSettings(settings));
};

export const setSettings = (settings) => async (dispatch) => {
  await secure('admin/settings/update', { settings });
  dispatch(doSetSettings(settings));
};
