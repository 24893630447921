import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import i18n from 'i18next';
import { getLang } from 'App/redux/selectors';

const LocaleWatch = () => {
  const lang = useSelector(getLang);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return <div />;
};

export default LocaleWatch;
