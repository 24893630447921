import React from 'react';
import ResizeDetector from 'react-resize-detector';

const withResize = (WrappedComponent) => (debounce) => (props) => (
  <ResizeDetector
    handleWidth
    handleHeight
    {...(debounce ? { refreshMode: 'debounce', refreshRate: debounce } : {})}
		>
    <WrappedComponent {...props} />
  </ResizeDetector>
);

export default withResize;
