import { useEffect, useState } from 'react';

const checkConnection = () => navigator.onLine;

const useConnection = () => {
	const [connected, setConnected] = useState(checkConnection());

	const onChange = () => {
		setConnected(checkConnection());
	};

	useEffect(() => {
		window.addEventListener('online', onChange);
		window.addEventListener('offline', onChange);
		return () => {
			window.removeEventListener('online', onChange);
			window.removeEventListener('offline', onChange);
		};
	}, []);

	return connected;
};

export default useConnection;
