import store from 'store';
import Axios from 'axios';
import logger from 'js-logger';
import { getQueue } from './redux/selectors';
import { setQueue } from './redux/actions';
import tokenHandler from '../utils/tokenHandler';

Axios.interceptors.request.use((config) => {
  const token = tokenHandler.getToken();
  config.headers.Authorization = token;

  return config;
});

class AjaxHandler {
  constructor() {
    this.sending = false;
  }

  async connect() {
    this._send();
  }

  getKey() {
    return `i${Math.round(Math.random * 10000)}_${new Date().getTime()}`;
  }

  getQueue() {
    return getQueue(store.getState());
  }

  async test() {
    return await this.post('user', 'test');
  }

  async post(event, data, config) {
    logger.info('Ajax post with', event, data);
    try {
      return (await Axios.post(`/api/${event}`, data)).data;
    } catch (e) {
      console.log('post error', e, e.message, e.code, e.response);
      switch (e.response && e.response.status) {
        case 520:
          if (config) {
            document.location.href = '/moodle/user-error';
          } else {
            document.location.href = '/error/unknown';
          }
          return undefined;
        default:
          return new Promise((resolve) => {
            setTimeout(async () => {
              logger.warn('Ajax post failed, retrying');
              resolve(await this.post(event, data));
            }, 1000);
          });
      }
    }
  }

  async get(event, data) {
    logger.info('Ajax get with', event, data);
    try {
      return (await Axios.get(`/${event}`, data)).data;
    } catch (e) {
      return new Promise((resolve) => {
        setTimeout(async () => {
          logger.warn('Ajax get failed, retrying');
          resolve(await this.get(event, data));
        }, 1000);
      });
    }
  }

  async getOnce(event, data) {
    logger.info('Ajax get with', event, data);
    try {
      return (await Axios.get(`/${event}`, data)).data;
    } catch (e) {
      return null;
    }
  }

  async _send() {
    if (!this.sending && this.getQueue().length) {
      const queue = this.getQueue();
      this.sending = true;
      const { event, data } = queue[queue.length - 1];
      store.dispatch(setQueue(queue.slice(0, queue.length - 1)));
      await this.post(event, data);
      this.sending = false;
      this._send();
    }
  }

  async send(event, data) {
    store.dispatch(setQueue([{ event, data }, ...this.getQueue()]));
    this._send();
  }

  async fetch(event, data, config) {
    this.sending = true;
    const result = await this.post(event, data, config);
    this.sending = false;
    return result;
  }

  async file(event, once) {
    this.sending = true;
    const result = once ? await this.getOnce(event) : await this.get(event);
    this.sending = false;
    return result;
  }
}

export default new AjaxHandler();
