import logger from 'js-logger';

import { createAction } from 'store/utils';
import ajaxHandler from 'network/ajaxHandler';
import { setLoading } from 'App/redux/actions';

import store from 'store';
import {
  TYPES, SET_EXPLANATION, SET_EXPLANATION_OPEN, SET_SELECTED_DRAWING, SET_SECTION, SET_CURRENT_INDEX,
} from './constants';
import { setBackground } from '../../Drawer/redux/actions';
import { getCurrentIndex } from './selectors';
import { history } from '../../../routes';

export const setExplanation = createAction(TYPES[SET_EXPLANATION]);
export const setExplanationOpen = createAction(TYPES[SET_EXPLANATION_OPEN]);
export const setSelectedDrawing = createAction(TYPES[SET_SELECTED_DRAWING]);
export const doSetSection = createAction(TYPES[SET_SECTION]);
export const setSection = createAction(TYPES[SET_SECTION]);
export const setCurrentIndex = createAction(TYPES[SET_CURRENT_INDEX]);

const { BACKGROUND_IDS } = window.CONFIG;

export const prevExplanation = () => async (dispatch) => {
  const currentIndex = getCurrentIndex(store.getState());
  const nextIndex = currentIndex - 1;
  if (BACKGROUND_IDS[nextIndex]) {
    dispatch(setCurrentIndex(nextIndex));
  }
};

export const nextExplanation = (nextRoute) => async (dispatch) => {
  const currentIndex = getCurrentIndex(store.getState());
  const nextIndex = currentIndex + 1;
  if (BACKGROUND_IDS[nextIndex]) {
    dispatch(setCurrentIndex(nextIndex));
  } else {
    dispatch(setCurrentIndex(0));
    history.push(nextRoute);
  }
};

export const fetchExplanationBackground = (currentIndex) => async (dispatch) => {
  dispatch(setBackground());

  const backgroundId = BACKGROUND_IDS[currentIndex];

  dispatch(setLoading(true));
  const background = await ajaxHandler.fetch('moodle/background', { backgroundId });

  dispatch(setBackground({ ...background, url: `/${background.url}` }));
  dispatch(setLoading(false));
};

export const fetchExplanation = (backgroundId) => async (dispatch) => {
  dispatch(setExplanation());
  dispatch(setSelectedDrawing());
  dispatch(setSection('explanation'));
  dispatch(setLoading(true));
  try {
    const result = await ajaxHandler.file(`explanations/${backgroundId}.json`, true);
    const {
      drawings, color, selectedColor, author, subject, title,
    } = result.users[0];

    dispatch(
      setExplanation({
        drawings: drawings[Object.keys(drawings)[0]],
        color,
        selectedColor,
        author,
        subject,
        title,
      }),
    );
  } catch (e) {
    logger.warn('Sessions list fetch error ', e.status, e.message);
  }
  dispatch(setLoading(false));
};
