import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useConnection from 'hooks/useConnection';
import Ratio from 'components/Ratio';
import { Logo } from 'components/Overlay';
import { useTranslation } from 'react-i18next';
import { getSessions, getSessionsDrawing } from '../redux/selectors';
import { initHandlers, stopHandlers } from './actions';
import Session from '../Session';
import Overlay from '../../../components/Overlay';
import { getRestitutionOpen } from '../../../App/redux/selectors';
import Explanation from '../../../components/Explanation';
import { getExplanationOpen } from '../../../components/Explanation/redux/selectors';

import './viewer.scss';

const Viewer = () => {
  const connected = useConnection();

  const sessions = useSelector(getSessions);
  const sessionsDrawing = useSelector(getSessionsDrawing);
  const restitutionOpen = useSelector(getRestitutionOpen);
  const explanationOpen = useSelector(getExplanationOpen);

  const { t } = useTranslation();

  useEffect(
    () => {
      if (connected) {
        stopHandlers();
        initHandlers();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connected],
  );

  const displayLogo = useMemo(
    () => {
      if (sessions && sessions.length) {
        return false;
      }
      if (explanationOpen) {
        return false;
      }
      return true;
    },
    [explanationOpen, sessions],
  );

  const computedSessions = useMemo(() => {
    if (!sessionsDrawing) {
      return sessions;
    }
    return sessions.map((session) => {
      if (session.id === sessionsDrawing.sessionId) {
        return { ...session, selectedDrawing: sessionsDrawing };
      }
      return session;
    });
  }, [sessions, sessionsDrawing]);

  const content = useMemo(() => {
    if (computedSessions) {
      if (computedSessions.length === 1) {
        const [session] = computedSessions;
        return <Session {...session} />;
      }
      return (
        <>
          {computedSessions.map((session) => (
            <Ratio key={session.id}>
              <Session {...session} />
            </Ratio>
          ))}
        </>
      );
    }
    return null;
  }, [computedSessions]);

  return (
    <Overlay open={restitutionOpen}>
      <div className="viewer">
        {displayLogo ? <Logo open /> : (
          <>
            {explanationOpen ? (
              <Explanation
                disabled
                defaultText={t('restitution.viewerDefaultText')}
              />
            ) : <div className="sessions">{content}</div>}
          </>
        )}
      </div>
    </Overlay>
  );
};

export default Viewer;
