import { createReducer, assignPayloadToKey } from 'store/utils';
import { modeOptions } from 'modules/Restitution';
import {
  TYPES,
  STUDENTS,
  SET_STUDENTS,
  SET_SESSIONS_LIST,
  SESSIONS_LIST,
  SET_SESSIONS,
  SESSIONS,
  SET_CORPUS,
  CORPUS,
  SET_NUMBER,
  NUMBER,
  SET_SUPERPOSE,
  SUPERPOSE,
  SET_MODE,
  MODE,
  SET_CORPUS_LIST,
  CORPUS_LIST,
  SET_STUDENTS_LIST,
  STUDENTS_LIST,
  SET_DATA,
  DATA,
  RESULT,
  SET_RESULT,
  SET_SETTINGS_OPEN,
  SETTINGS_OPEN,
  SLIDERS_OPEN,
  SET_SLIDERS_OPEN,
  SET_LINE_WIDTH,
  SET_BLUR,
  SET_VALUE,
  LINE_WIDTH,
  BLUR,
  VALUE,
  SET_SELECTED_SESSIONS,
  SELECTED_SESSIONS,
  SET_SETTINGS,
  SETTINGS,
  SET_SURVEY_OPTIONS,
  SURVEY_OPTIONS,
  SURVEY_FILTER,
  SET_SURVEY_FILTER,
  FILE,
  SET_FILE, SESSIONS_DRAWING, SET_SESSIONS_DRAWING,
} from './constants';

const [, defaultOption] = modeOptions;

export const defaultSettings = {
  [MODE]: { ...defaultOption, label: 'Lignes' },
  [LINE_WIDTH]: 10,
  [BLUR]: 5,
  [VALUE]: 50,
};

const defaultState = {
  [STUDENTS]: [],
  [SUPERPOSE]: true,

  [NUMBER]: 20,
  [SETTINGS_OPEN]: true,
  [SELECTED_SESSIONS]: {},
  [SETTINGS]: {},
  [SURVEY_OPTIONS]: [],
  [SURVEY_FILTER]: {/*
    101: [
      {
        label: 'La Laitière de Johannes Vermeer',
        value: '10001',
      },
      {
        label: 'Famille de Paysans de Louis Le Nain',
        value: '10002',
      },
      {
        label: 'La photographie Le Stryge de Charles Nègre',
        value: '10004',
      },
      {
        label: 'Le paysage Gelée blanche de Camille Pissarro',
        value: '10003',
      },
      {
        label: "L'œuvre abstraite Manège de cochons de Robert Delaunay",
        value: '10005',
      },
    ],
    102: [
      {
        label: 'Oui',
        value: '20001',
      },
    ],
    103: [
      {
        label: 'Dans le domaine de la création artistique',
        value: '30002',
      },
    ],
    104: [
      {
        label: 'Consulter des commentaires sur des oeuvres',
        value: '40002',
      },
      {
        label: 'Suivre un cours, un MOOC, dédié à l’histoire de l’art',
        value: '40001',
      },
    ],
    105: [
      {
        label: 'Seul(e)',
        value: '50001',
      },
    ],
    114: [
      {
        label: 'CAP, BEP',
        value: '140003',
      },
    ], */
  },
  [SESSIONS]: [],
  [CORPUS_LIST]: window.CONFIG.CORPUS_LIST,
};

const behaviors = {
  [TYPES[SET_SESSIONS_LIST]]: assignPayloadToKey(SESSIONS_LIST),
  [TYPES[SET_SESSIONS]]: assignPayloadToKey(SESSIONS),
  [TYPES[SET_SESSIONS_DRAWING]]: assignPayloadToKey(SESSIONS_DRAWING),
  [TYPES[SET_CORPUS_LIST]]: assignPayloadToKey(CORPUS_LIST),
  [TYPES[SET_CORPUS]]: assignPayloadToKey(CORPUS),
  [TYPES[SET_STUDENTS_LIST]]: assignPayloadToKey(STUDENTS_LIST),
  [TYPES[SET_STUDENTS]]: assignPayloadToKey(STUDENTS),
  [TYPES[SET_NUMBER]]: assignPayloadToKey(NUMBER),
  [TYPES[SET_SUPERPOSE]]: assignPayloadToKey(SUPERPOSE),
  [TYPES[SET_MODE]]: assignPayloadToKey(MODE),
  [TYPES[SET_DATA]]: assignPayloadToKey(DATA),
  [TYPES[SET_RESULT]]: assignPayloadToKey(RESULT),
  [TYPES[SET_FILE]]: assignPayloadToKey(FILE),
  [TYPES[SET_SETTINGS_OPEN]]: assignPayloadToKey(SETTINGS_OPEN),
  [TYPES[SET_SLIDERS_OPEN]]: assignPayloadToKey(SLIDERS_OPEN),

  [TYPES[SET_LINE_WIDTH]]: assignPayloadToKey(LINE_WIDTH),
  [TYPES[SET_BLUR]]: assignPayloadToKey(BLUR),
  [TYPES[SET_VALUE]]: assignPayloadToKey(VALUE),
  [TYPES[SET_SELECTED_SESSIONS]]: assignPayloadToKey(SELECTED_SESSIONS),
  [TYPES[SET_SETTINGS]]: assignPayloadToKey(SETTINGS),
  [TYPES[SET_SURVEY_OPTIONS]]: assignPayloadToKey(SURVEY_OPTIONS),
  [TYPES[SET_SURVEY_FILTER]]: assignPayloadToKey(SURVEY_FILTER),
};

export default createReducer(behaviors, defaultState);
