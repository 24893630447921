import { createTypes } from 'store/utils';

export const KEY = 'application';

export const USER = 'user';
export const SET_USER = 'SET_USER';

export const LOADING = 'loading';
export const SET_LOADING = 'SET_LOADING';

export const GROUP_ID = 'groupId';
export const SET_GROUP_ID = 'SET_GROUP_ID';

export const LANG = 'lang';
export const SET_LANG = 'SET_LANG';

export const LANGS_OPEN = 'langsOpen';
export const SET_LANGS_OPEN = 'SET_LANGS_OPEN';

export const ROLE = 'role';
export const SET_ROLE = 'SET_ROLE';

export const GROUP_CLOSED = 'groupClosed';
export const SET_GROUP_CLOSED = 'SET_GROUP_CLOSED';

export const SHARED_DRAWINGS = 'sharedDrawings';
export const SET_SHARED_DRAWINGS = 'SET_SHARED_DRAWINGS';

export const RESTITUTION_OPEN = 'restitutionOpen';
export const SET_RESTITUTION_OPEN = 'SET_RESTITUTION_OPEN';

export const DISPLAY_SURVEY = 'displaySurvey';
export const SET_DISPLAY_SURVEY = 'SET_DISPLAY_SURVEY';

export const ENDING_SURVEY = 'endingSurvey';
export const SET_ENDING_SURVEY = 'SET_ENDING_SURVEY';

export const DISPLAY_TUTORIAL = 'displayTutorial';
export const SET_DISPLAY_TUTORIAL = 'SET_DISPLAY_TUTORIAL';

export const DISPLAY_DRAWING_COMMENT = 'displayDrawingComment';
export const SET_DISPLAY_DRAWING_COMMENT = 'SET_DISPLAY_DRAWING_COMMENT';

export const TUTORIAL_BACKGROUND = 'tutorialBackground';
export const SET_TUTORIAL_BACKGROUND = 'SET_TUTORIAL_BACKGROUND';

export const MyTypesArray = [
  SET_GROUP_ID,
  SET_USER,
  SET_ROLE,
  SET_LOADING,
  SET_LANG,
  SET_LANGS_OPEN,
  SET_GROUP_CLOSED,
  SET_SHARED_DRAWINGS,
  SET_RESTITUTION_OPEN,
  SET_DISPLAY_SURVEY,
  SET_ENDING_SURVEY,
  SET_DISPLAY_TUTORIAL,
  SET_TUTORIAL_BACKGROUND,
  SET_DISPLAY_DRAWING_COMMENT,
];
export const TYPES = createTypes(KEY, MyTypesArray);
