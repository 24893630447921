import React, { useRef, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { types, modes } from './constants';

export const doDraw = (context, dots, color, xFactor, yFactor) => {
  const [{ x, y }] = dots;
  context.beginPath();
  context.lineCap = 'butt';
  context.strokeStyle = `#${color.color2}`;
  context.lineWidth = 5;
  context.moveTo(x * xFactor, y * yFactor);

  for (let i = 1; i < dots.length; i++) {
    const { x, y } = dots[i];
    context.lineTo(x * xFactor, y * yFactor);
  }
  context.stroke();

  context.lineCap = 'round';
  context.strokeStyle = `#${color.color1}`;
  context.lineWidth = 2;

  context.moveTo(x * xFactor, y * yFactor);
  for (let i = 1; i < dots.length; i++) {
    const { x, y } = dots[i];
    context.lineTo(x * xFactor, y * yFactor);
  }
  context.stroke();
};

export const doDrawDot = (context, dots, color, xFactor, yFactor) => {
  const [{ x, y }] = dots;
  context.beginPath();
  context.fillStyle = `#${color.color1}`;
  context.arc(x * xFactor, y * yFactor, 5, 0, 2 * Math.PI);
  context.fill();

  context.strokeStyle = `#${color.color2}`;
  context.arc(x * xFactor, y * yFactor, 5, 0, 2 * Math.PI);
  context.stroke();
};

export const doDrawMask = (context, dots, color, xFactor, yFactor) => {
  const [{ x, y }] = dots;

  context.beginPath();
  context.fillStyle = `#${color.color2}`;
  context.moveTo(x * xFactor, y * yFactor);

  for (let i = 1; i < dots.length; i++) {
    const { x, y } = dots[i];
    context.lineTo(x * xFactor, y * yFactor);
  }

  context.fill();
};

export const doDrawInvertedMask = (context, dots, color, xFactor, yFactor, width, height) => {
  const [{ x, y }] = dots;
  const drawRect = () => {
    context.beginPath();
    context.fillStyle = '#333';
    context.fillRect(0, 0, width, height);
  };

  const drawPath = () => {
    context.beginPath();
    context.moveTo(x * xFactor, y * yFactor);
    for (let i = 1; i < dots.length; i++) {
      const { x, y } = dots[i];
      context.lineTo(x * xFactor, y * yFactor);
    }
    context.fill();
  };

  context.globalCompositeOperation = 'xor';
  drawRect();
  drawPath();
  context.globalCompositeOperation = 'source-over';
};

export const doDrawing = (type, mode, context, dots, color, xFactor, yFactor, width, height) => {
  if (type === types.SHAPE) {
    const method = {
      [modes.MASK]: doDrawMask,
      [modes.INVERTED_MASK]: doDrawInvertedMask,
    }[mode];
    if (method) {
      method(context, dots, color, xFactor, yFactor, width, height);
    }
  } else if (type === types.DOT) {
    doDrawDot(context, dots, color, xFactor, yFactor, width, height);
  }

  doDraw(context, dots, color, xFactor, yFactor, width, height);
};

export const Drawing = ({
  drawing, width, height, style, className, color, onClick,
}) => {
  const canvas = useRef();

  useEffect(() => {
    canvas.current.width = width;
    canvas.current.height = height;
    const context = canvas.current.getContext('2d');
    context.clearRect(0, 0, width, height);
    const xFactor = width / drawing.width;
    const yFactor = height / drawing.height;

    const {
      dots, type, mode, subDrawings,
    } = drawing;

    if (subDrawings) {
      subDrawings.forEach((subDots) => doDrawing(
        type, mode, context, subDots, color, xFactor, yFactor, width, height,
      ));
    } else {
      doDrawing(type, mode, context, dots, color, xFactor, yFactor, width, height);
    }
  }, [color, drawing, height, width]);

  return (
    <canvas
      ref={canvas}
      className={classNames('drawing', className, { hidden: drawing.hidden })}
      style={style}
      onClick={onClick}
    />
  );
};

Drawing.propTypes = {
  drawing: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.object.isRequired,
};

Drawing.defaultProps = {
  style: undefined,
  className: '',
};

export default Drawing;
