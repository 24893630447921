import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Options = ({ options, valuesMap, multiple, onSelect }) => options ? (
		<Fragment>
			{options.map(option => (
				<div
					key={option.value}
					className={classNames('option', {
						selected: valuesMap[option.value],
					})}
					onClick={() => {
						onSelect(option);
					}}
				>
					{option.label}
				</div>
			))}
		</Fragment>
	) : null;

Options.propTypes = {
	multiple: PropTypes.bool,
	valuesMap: PropTypes.object.isRequired,
	options: PropTypes.arrayOf(PropTypes.object),
	onSelect: PropTypes.func.isRequired,
};

Options.defaultProps = {
	grouped: false,
	multiple: false,
	options: undefined,
};

export default Options;
